import React, { useState, useEffect } from 'react';
import './CheckoutStep2.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import DOMPurify from 'dompurify';

const CheckoutStep2 = ({ cartItems, exchange_rate, balance }) => {
  const [user] = useAuthState(auth);
  const [selectedFacturacion, setselectedFacturacion] = useState('Personal');
  const [selectedEnvio, setselectedEnvio] = useState('Personal');
  const [errorPlacingOrder, seterrorPlacingOrder] = useState('');
  const [errorSame, setsetErrorSame] = useState('');
  const [billingAddresses, setbillingAddresses] = useState([]);
  const [shippingAddresses, setshippingAddresses] = useState([]);

  let user_uid = null;
  if (user) {
    user_uid = user.uid;
  }

  const baseURL = process.env.NODE_ENV === 'production' ? "https://ivyrevest.com/api/" : "http://localhost:5000/api/";

  const nombrePattern = /^[A-Za-zÁÉÍÓÚáéíóúñÑ\s]+$/;
  const cedulaPattern = /^[0-9]{7,8}$/;
  const direccionPattern = /^[A-Za-z0-9\s'\\|,-.]*$/;
  const estadoPattern = /^[A-Za-z]+$/;
  const codigoPattern = /^[0-9]+$/;
  const telefonoPattern = /^[0-9+().-]*$/;
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const rifPattern = /^[EIJVG]-\d{8}-\d$/;

  const [billingDetails, setBillingDetails] = useState({
    nombre: '',
    apellidos: '',
    cedula: '',
    direccion: '',
    estado: '',
    municipio: '',
    codigo: '',
    telefono: '',
    email: '',
    company: '',
    rif: '',
  });

  const [shippingDetails, setShippingDetails] = useState({
    nombre: '',
    apellidos: '',
    direccion: '',
    estado: '',
    municipio: '',
    codigo: '',
    telefono: '',
    email: '',
    company: '',
    rif: '',
  });

  const [notes, setNotes] = useState({
    notas: '',
  });

  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');

  const handlePaymentOptionChange = (event) => {
    setSelectedPaymentOption(event.target.value);
  };

  const handleBillingChanges= (event) => {
    const { name, value } = event.target;
    setBillingDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const navigate = useNavigate();

  const handleShippingChanges= (event) => {
    const { name, value } = event.target;
    setShippingDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleNotesChanges= (event) => {
    const { name, value } = event.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setNotes((prevDetails) => ({ ...prevDetails, [name]: sanitizedValue }));
  };

  const [billingFormVisible, setBillingFormVisible] = useState(false);
  const [shippingFormVisible, setShippingFormVisible] = useState(false);
  const [paymentFormVisible, setPaymentFormVisible] = useState(false);
  const [hideForm, setHideForm] = useState(false);

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const getTotalPriecBs = () => {
    return cartItems.reduce((total, item) => total + item.priceBs * item.quantity, 0).toFixed(2);
  };

  const shippingDollars = parseFloat((5));
  const shippingBs = parseFloat((5 * exchange_rate));
  const totalDollars = parseFloat(getTotalPrice());
  const totalBs = parseFloat(getTotalPriecBs());


  const handleCheckboxChange = () => {

    const fieldsJuridico = ['direccion', 'estado', 'municipio', 'codigo', 'telefono', 'email', 'company', 'rif'];
    const fieldsPersonal = ['direccion', 'estado', 'municipio', 'codigo', 'telefono', 'email', 'nombre', 'apellidos', 'cedula'];

    let isBillingFullJuridico = false;
    let isBillingFullPersonal = false;

    if (selectedFacturacion === "Jurídico") {
       // check billing address != '' except nombre appelidom and cedula
      isBillingFullJuridico = fieldsJuridico.every((field) => {
        return billingDetails[field] !== '';
      })
    } else if (selectedFacturacion === "Personal") {
      // check billing address != '' except company and rif
      isBillingFullPersonal = fieldsPersonal.every((field) => {
        return billingDetails[field] !== '';
      })
    }

    if (isBillingFullJuridico || isBillingFullPersonal) {
      setHideForm(!hideForm);
      if (!hideForm) {
        setShippingDetails({ ...billingDetails });
        setselectedEnvio(selectedFacturacion);
	setsetErrorSame('');
      }
    } else {
      setHideForm(false);
      setsetErrorSame('Por favor primero completar toda la información de facturación.');
    }
  };

  const handleBillingFormToggle = () => {
    setBillingFormVisible(!billingFormVisible);
  };

  const handlePaymentFormToggle = () => {
    setPaymentFormVisible(!paymentFormVisible);
  };

  const handleShippingFormToggle = () => {
    setShippingFormVisible(!shippingFormVisible);
  };

  const handleselectedFacturacion = (event) => {
    setselectedFacturacion(prevFacturacion =>
      prevFacturacion === event.target.value ? '' : event.target.value
    );
// billing details
     if (event.target.value === 'Personal') {
      // set company and rif to ''
      setBillingDetails(prevDetails => ({...prevDetails, company: '', rif: ''}));
    } else {
      // set nombre, apellido, cedula to ''
      setBillingDetails(prevDetails => ({...prevDetails, nombre: '', apellidos: '', cedula: ''}));
    }
  }

  const handleselectedEnvio = (event) => {
    setselectedEnvio(prevEnvio =>
      prevEnvio === event.target.value ? '' : event.target.value
    );
    if (event.target.value === 'Personal') {
      // set company and rif to ''
      setShippingDetails(prevDetails => ({...prevDetails, company: '', rif: ''}));
    } else {
      // set nombre, apellido, cedula to ''
      setShippingDetails(prevDetails => ({...prevDetails, nombre: '', apellidos: '', cedula: ''}));
    }
  }

  const placeOrder = async () => {

    // Check if for shipping and billing juridico or personal is not empty
    const fieldsBillingJuridico = ['direccion', 'estado', 'municipio', 'codigo', 'telefono', 'email', 'company', 'rif'];
    const fieldsBillingPersonal = ['direccion', 'estado', 'municipio', 'codigo', 'telefono', 'email', 'nombre', 'apellidos', 'cedula'];

    const fieldsShippingJuridico = ['direccion', 'estado', 'municipio', 'codigo', 'telefono', 'email', 'company', 'rif'];
    const fieldsShippingPersonal = ['direccion', 'estado', 'municipio', 'codigo', 'telefono', 'email', 'nombre', 'apellidos'];

    let isBillingFullJuridico = false;
    let isBillingFullPersonal = false;
    let isShippingFullJuridico = false;
    let isShippingFullPersonal = false;

    if (selectedFacturacion === "Jurídico") {
      isBillingFullJuridico = fieldsBillingJuridico.every((field) => {
        return billingDetails[field] !== '';
      })
    } else if (selectedFacturacion === "Personal") {
      isBillingFullPersonal = fieldsBillingPersonal.every((field) => {
        return billingDetails[field] !== '';
      })
    }

    if (selectedEnvio === "Jurídico") {
      isShippingFullJuridico = fieldsShippingJuridico.every((field) => {
      return shippingDetails[field] !== '';
     })
   } else if (selectedEnvio === "Personal") {
      isShippingFullPersonal = fieldsShippingPersonal.every((field) => {
      return shippingDetails[field] !== '';
     })
   }

   // if fields still empty
   if (!(isBillingFullJuridico || isBillingFullPersonal) || !(isShippingFullJuridico || isShippingFullPersonal) || selectedPaymentOption === '') { 
    seterrorPlacingOrder('Por favor completar todos los campos');
    return;
   }

   // If juridico check for company and rif errors
   if (selectedEnvio === "Jurídico") {
      if (!rifPattern.test(shippingDetails.rif)) {
        seterrorPlacingOrder("Por favor corrija los campos con errores antes de realizar el pedido.");
        return;
      }
   }
  
   if (selectedFacturacion === "Jurídico") {
    if (!rifPattern.test(billingDetails.rif)) {
      seterrorPlacingOrder("Por favor corrija los campos con errores antes de realizar el pedido.");
      return;
    }
   }

   // If personal check for nombre, apellidos and cedula errors
    if (selectedEnvio === "Personal") {
      if (!nombrePattern.test(shippingDetails.nombre) || !nombrePattern.test(shippingDetails.apellidos) || !cedulaPattern.test(shippingDetails.cedula)) {
        seterrorPlacingOrder("Por favor corrija los campos con errores antes de realizar el pedido.");
        return;
      } 
    }

   if (selectedFacturacion === "Personal") {
    if (!nombrePattern.test(billingDetails.nombre) || !nombrePattern.test(billingDetails.apellidos) || !cedulaPattern.test(billingDetails.cedula)) {
      seterrorPlacingOrder("Por favor corrija los campos con errores antes de realizar el pedido.");
      return;
    }
   }

   // Check for fields in common
    if (!direccionPattern.test(billingDetails.direccion) || !estadoPattern.test(billingDetails.estado) || !estadoPattern.test(billingDetails.municipio) || 
    !codigoPattern.test(billingDetails.codigo) || !telefonoPattern.test(billingDetails.telefono) || !emailPattern.test(billingDetails.email) || 
    !direccionPattern.test(shippingDetails.direccion) || !estadoPattern.test(shippingDetails.estado) || !estadoPattern.test(shippingDetails.municipio) || 
    !codigoPattern.test(shippingDetails.codigo) || !telefonoPattern.test(shippingDetails.telefono) || !emailPattern.test(shippingDetails.email)) {
      seterrorPlacingOrder("Por favor corrija los campos con errores antes de realizar el pedido.");
      return;
    }

    if ((isBillingFullJuridico || isBillingFullPersonal) && (isShippingFullJuridico || isShippingFullPersonal) && selectedPaymentOption !== '') {      
      try {
        const response = await fetch(`${baseURL}placeOrder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            billingDetails,
            shippingDetails,
            selectedPaymentOption,
            cartItems,
            notes,
            user_uid,
            totalDollars,
            totalBs,
            selectedEnvio,
            selectedFacturacion,
	    balance,
          }),
        });

	console.log(response);
  
        if (response.ok) {
          const data = await response.json();
          const orderID = data.orderID;

          navigate(`/checkout/checkoutStep2/orderPlaced/${orderID}`, {
            state: {
              orderId: orderID,
              paymentMethod: selectedPaymentOption,
              billingDetails,
              shippingDetails,
              orderItems: cartItems,
              totalDollars: totalDollars,
              totalBs: totalBs,
              notes: notes.notas,
              shippingDollars: shippingDollars,
              shippingBs: shippingBs,
              selectedEnvio,
              selectedFacturacion,
            },
          });
        } 
      } catch (error) {
        console.error('Error placing order:', error.message);
      }
    } else {
      seterrorPlacingOrder('Por favor completar todos los campos');
    }
  };

  const fetchOrders = async () => {
    try {
      const token = user && await user.getIdToken();
      const headers = token ? {authToken: token} : {};
      const response = await fetch(`${baseURL}getOrdersAddresses/user/${encodeURIComponent(user.uid)}`, {
        headers: headers
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const orders = await response.json();
      // make a set of billing and shipping addresses
      const billingAddressesSet = new Set();
      const shippingAddressesSet = new Set();

      orders.forEach(order => {
        const billingDetailsKey = JSON.stringify(order.billing_details);
        const shippingDetailsKey = JSON.stringify(order.shipping_details);

        billingAddressesSet.add(billingDetailsKey);
        shippingAddressesSet.add(shippingDetailsKey);
      });

      const billingAddressesArr = Array.from(billingAddressesSet).map(JSON.parse);
      const shippingAddressesArr = Array.from(shippingAddressesSet).map(JSON.parse);

      setbillingAddresses(billingAddressesArr);
      setshippingAddresses(shippingAddressesArr);

    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleIndexFacturacion = (event) => {
    const index = event.target.selectedIndex;
    if (index > 0) {
      setBillingDetails({
        ...billingAddresses[index-1]
      });
      if (billingAddresses[index-1].nombre === '') {
        setselectedFacturacion('Jurídico');
      } else {
        setselectedFacturacion('Personal');
      }
    } else {
      setBillingDetails({nombre: '', apellidos: '', cedula: '', direccion: '', estado: '', municipio: '', codigo: '', telefono: '', email: '', company: '', rif: ''});
      setselectedFacturacion('Personal');
    }
  };

  const handleIndexShipping = (event) => {
    const index = event.target.selectedIndex;
    if (index > 0) {
      setShippingDetails({
        ...shippingAddresses[index-1]
      });
      if (shippingAddresses[index-1].nombre === '') {
        setselectedEnvio('Jurídico');
      } else {
        setselectedEnvio('Personal');
      }
    } else {
      setShippingDetails({nombre: '', apellidos: '', cedula: '', direccion: '', estado: '', municipio: '', codigo: '', telefono: '', email: '', company: '', rif: ''});
      setselectedEnvio('Personal');
    }
  };

  useEffect(() => {
    if (user) {
      fetchOrders();
    }
  }, [user]);
   
  return (
    <div className="checkout-step2-container">

      <div className='facturacion-column'>

        <p className="titulo-checkout2" onClick={handleBillingFormToggle}>
          <p>INFORMACIÓN DE FACTURACIÓN</p> 
          <p>{billingFormVisible ? '▼' : '▲'}</p>
      </p>

        {billingFormVisible && (
          
          <form className="form-container">
	
	 <div className='column-smallscreen' style={{marginBottom: '1rem'}}>
            <span className="sort-by-label">Direcciones utilizadas: </span>
              <div className="dropdown2">
                <select onChange={handleIndexFacturacion}>
                  <option value={1}>Agregar nueva dirección</option>

                  {billingAddresses.map((billingAddress, index) => {

                      return (
                      <option key={index} value={index+1}>
                        {/* If nombre === '', then juridico, else personal */}
                        {billingAddress.nombre === '' ? (
                          billingAddress.company + ' ' + billingAddress.rif + '. ' + billingAddress.direccion +
                        ', ' + billingAddress.estado + ', ' + billingAddress.municipio + ', ' + billingAddress.codigo + ', ' + billingAddress.telefono +
                        ', ' + billingAddress.email
                        ): (
                          billingAddress.nombre + ' ' + billingAddress.apellidos + ' ' + billingAddress.cedula + '. ' + billingAddress.direccion +
                        ', ' + billingAddress.estado + ', ' + billingAddress.municipio + ', ' + billingAddress.codigo + ', ' + billingAddress.telefono +
                        ', ' + billingAddress.email
                        )}
                      </option>
                      );
                  })}
                </select>
              </div>
            </div>
            
          <div className='juridico-personal'>
            <label className='brand-name-checkbox' style={{marginRight: '0.7rem'}}>
              <input
                type="radio"
                name="facturacionOption"
                value="Personal"
                checked={selectedFacturacion === "Personal"}
                onChange={handleselectedFacturacion}
              />
              Personal
            </label>
            <label className='brand-name-checkbox'>
              <input
                type="radio"
                name="facturacionOption"
                value="Jurídico"
                checked={selectedFacturacion === "Jurídico"}
                onChange={handleselectedFacturacion}
              />
              Jurídico
            </label>
          </div>

          <div className="form-column">
            {selectedFacturacion === "Personal" && (
              <div className='form-column'>
                <label>Nombre *</label>
                <input type="text" name="nombre" value={billingDetails.nombre} onChange={handleBillingChanges} required maxLength={30} />
                {billingDetails.nombre.trim() !== '' && !nombrePattern.test(billingDetails.nombre) && (
                  <p className="error-message">Nombre inválido. Solo se permiten letras y espacios y tener un máximo de 30 caracteres.</p>
                )}

                <label>Apellido *</label>
                <input type="text" name="apellidos" value={billingDetails.apellidos} onChange={handleBillingChanges} required  maxLength={30} />
                {billingDetails.apellidos.trim() !== '' && !nombrePattern.test(billingDetails.apellidos) && (
                  <p className="error-message">Apellido inválido. Solo se permiten letras y espacios y tener un máximo de 30 caracteres.</p>
                )}

                <label>Cédula de Identidad *</label>
                <input type="text" name="cedula" value={billingDetails.cedula} onChange={handleBillingChanges} required  maxLength={8} />
                {billingDetails.cedula.trim() !== '' && !cedulaPattern.test(billingDetails.cedula) && (
                  <p className="error-message">Cédula inválida. Debe tener 7-8 dígitos.</p>
                )}
              </div>
            )}

            {selectedFacturacion === "Jurídico" && (
              <div className='form-column'>
                <label>Compañía *</label>
                <input type="text" name="company" value={billingDetails.company} onChange={handleBillingChanges} required maxLength={30} />

                <label>RIF *</label>
                <input type="text" name="rif" value={billingDetails.rif} onChange={handleBillingChanges} required maxLength={12} placeholder="ej: V-12345678-0"/>
                {billingDetails.rif.trim() !== '' && !rifPattern.test(billingDetails.rif) && (
                  <p className="error-message">RIF inválido. Debe tener el formato correcto, por ejemplo: V-12345678-0. </p>
                )}
              </div>
            )}

            <label>Dirección *</label>
            <input type="text" name="direccion" value={billingDetails.direccion} onChange={handleBillingChanges} required maxLength={100} />
            {billingDetails.direccion.trim() !== '' && !direccionPattern.test(billingDetails.direccion) && (
              <p className="error-message">Dirección inválida. Solo se permiten letras, dígitos, algunos caracteres y espacios.</p>
            )}


            <label>Estado *</label>
            <input type="text" name="estado" value={billingDetails.estado} onChange={handleBillingChanges} required maxLength={20 }/>
            {billingDetails.estado.trim() !== '' && !estadoPattern.test(billingDetails.estado) && (
              <p className="error-message">Estado inválido. Solo se permiten letras.</p>
            )}

            <label>Municipio *</label>
            <input type="text" name="municipio" value={billingDetails.municipio} onChange={handleBillingChanges} required maxLength={20} />
            {billingDetails.municipio.trim() !== '' && !estadoPattern.test(billingDetails.municipio) && (
              <p className="error-message">Municipio inválido. Solo se permiten letras.</p>
            )}

            <label>Código postal *</label>
            <input type="text" name="codigo" value={billingDetails.codigo} onChange={handleBillingChanges} required maxLength={6}/>
            {billingDetails.codigo.trim() !== '' && !codigoPattern.test(billingDetails.codigo) && (
              <p className="error-message">Código postal inválido. Solo se permiten dígitos.</p>
            )}

            <label>Teléfono *</label>
            <input type="text" name="telefono" value={billingDetails.telefono} onChange={handleBillingChanges} required maxLength={15} />
            {billingDetails.telefono.trim() !== '' && !telefonoPattern.test(billingDetails.telefono) && (
              <p className="error-message">Teléfono inválido. Solo se permiten dígitos y algunos caracteres.</p>
            )}

            <label>Email *</label>
            <input type="text" name="email" value={billingDetails.email} onChange={handleBillingChanges} required maxLength={50} />
            {billingDetails.email.trim() !== '' && !emailPattern.test(billingDetails.email) && (
              <p className="error-message">Correo electrónico inválido. Formato ejemplo@gmail.com</p>
            )}

          </div>
        </form>
        )}

      <p className="titulo-checkout2" onClick={handleShippingFormToggle}>
          <p>INFORMACIÓN DE ENVÍO</p> 
          <p>{shippingFormVisible ? '▼' : '▲'}</p>
      </p>

        {shippingFormVisible && (
          <div>

            <div className='checbox-checkout2'>
              <label>
                <input type="checkbox" checked={hideForm} onChange={handleCheckboxChange} />
                Misma dirección de facturación
              </label>
	      <p className="error-message">{errorSame}</p>
            </div>
            {!hideForm && (
              <form className="form-container">

		    <div className='column-smallscreen' style={{marginBottom: '1rem'}}>
            <span className="sort-by-label">Direcciones utilizadas: </span>
              <div className="dropdown2">
                <select onChange={handleIndexShipping}>
                  <option value={1}>Agregar nueva dirección</option>
                  {shippingAddresses.map((shippingAddress, index) => {

                      return (
                      <option key={index} value={index+1}>
                        {/* If nombre === '', then juridico, else personal */}
                        {shippingAddress.nombre === '' ? (
                          shippingAddress.company + ' ' + shippingAddress.rif + '. ' + shippingAddress.direccion +
                        ', ' + shippingAddress.estado + ', ' + shippingAddress.municipio + ', ' + shippingAddress.codigo + ', ' + shippingAddress.telefono +
                        ', ' + shippingAddress.email
                        ): (
                          shippingAddress.nombre + ' ' + shippingAddress.apellidos + ' ' + shippingAddress.cedula + '. ' + shippingAddress.direccion +
                        ', ' + shippingAddress.estado + ', ' + shippingAddress.municipio + ', ' + shippingAddress.codigo + ', ' + shippingAddress.telefono +
                        ', ' + shippingAddress.email
                        )}
                      </option>
                      );
                  })}
                </select>
              </div>
            </div>

                <div className='juridico-personal'>
                  <label className='brand-name-checkbox' style={{marginRight: '0.7rem'}}>
                    <input
                      type="radio"
                      name="envioOption"
                      value="Personal"
                      checked={selectedEnvio === "Personal"}
                      onChange={handleselectedEnvio}
                    />
                    Personal
                  </label>
                  <label className='brand-name-checkbox'>
                    <input
                      type="radio"
                      name="envioOption"
                      value="Jurídico"
                      checked={selectedEnvio === "Jurídico"}
                      onChange={handleselectedEnvio}
                    />
                    Jurídico
                  </label>
                </div>
                
                <div className="form-column">
                
                {selectedEnvio === "Personal" && (
                  <div className='form-column'>
                    <label>Nombre *</label>
                    <input type="text" name="nombre" value={shippingDetails.nombre} onChange={handleShippingChanges} required maxLength={30} />
                    {shippingDetails.nombre.trim() !== '' && !nombrePattern.test(shippingDetails.nombre) && (
                      <p className="error-message">Nombre inválido. Solo se permiten letras y espacios y tener un máximo de 30 caracteres.</p>
                    )}

                    <label>Apellido *</label>
                    <input type="text" name="apellidos" value={shippingDetails.apellidos} onChange={handleShippingChanges} required max={30} />
                    {shippingDetails.apellidos.trim() !== '' && !nombrePattern.test(shippingDetails.apellidos) && (
                      <p className="error-message">Apellido inválido. Solo se permiten letras y espacios y tener un máximo de 30 caracteres.</p>
                    )}

                  </div>
                )}

                {selectedEnvio === "Jurídico" && (
                  <div className='form-column'>
                    <label>Compañía *</label>
                    <input type="text" name="company" value={shippingDetails.company} onChange={handleShippingChanges} required maxLength={30} />
                   
                    <label>RIF *</label>
                    <input type="text" name="rif" value={shippingDetails.rif} onChange={handleShippingChanges} required maxLength={12} placeholder="ej: V-12345678-0"/>
                    {shippingDetails.rif.trim() !== '' && !rifPattern.test(shippingDetails.rif) && (
                      <p className="error-message">RIF inválido. Debe tener el formato correcto, por ejemplo: V-12345678-0. </p>
                    )}
                  </div>
                )}          

                <label>Dirección *</label>
                <input type="text" name="direccion" value={shippingDetails.direccion} onChange={handleShippingChanges} required maxLength={100} />
                {shippingDetails.direccion.trim() !== '' && !direccionPattern.test(billingDetails.direccion) && (
                  <p className="error-message">Dirección inválida. Solo se permiten letras, dígitos, algunos caracteres y espacios.</p>
                )}

                <label>Estado *</label>
                <input type="text" name="estado" value={shippingDetails.estado} onChange={handleShippingChanges} required maxLength={20} />
                {shippingDetails.estado.trim() !== '' && !estadoPattern.test(shippingDetails.estado) && (
                  <p className="error-message">Estado inválido. Solo se permiten letras.</p>
                )}
                
                <label>Municipio *</label>
                <input type="text" name="municipio" value={shippingDetails.municipio} onChange={handleShippingChanges} required maxLength={20} />
                {shippingDetails.municipio.trim() !== '' && !estadoPattern.test(shippingDetails.municipio) && (
                  <p className="error-message">Municipio inválido. Solo se permiten letras.</p>
                )}
                
                <label>Código postal *</label>
                <input type="text" name="codigo" value={shippingDetails.codigo} onChange={handleShippingChanges} required maxLength={5} />
                {shippingDetails.codigo.trim() !== '' && !codigoPattern.test(shippingDetails.codigo) && (
                  <p className="error-message">Código postal inválido. Solo se permiten dígitos.</p>
                )}
                
                <label>Teléfono *</label>
                <input type="text" name="telefono" value={shippingDetails.telefono} onChange={handleShippingChanges} required max={15} />
                {shippingDetails.telefono.trim() !== '' && !telefonoPattern.test(shippingDetails.telefono) && (
                  <p className="error-message">Teléfono inválido. Solo se permiten dígitos y carácter +.</p>
                )}

                <label>Email *</label>
                <input type="text" name="email" value={shippingDetails.email} onChange={handleShippingChanges} required maxLength={50} />
                {shippingDetails.email.trim() !== '' && !emailPattern.test(shippingDetails.email) && (
                  <p className="error-message">Correo electrónico inválido. Formato ejemplo@gmail.com</p>
                )}
                
                </div>
              </form>
            )}
            <div className="form-column">
              <label>Notas (optional)</label>
              <textarea
                name="notas"
                value={notes.notas}
                onChange={handleNotesChanges}
                style={{ height: '100px' }}
                className="notes-input"
                placeholder="Agregar cualquier nota sobre la orden o envío..."
                maxLength={200}
              />
              </div>
          </div>
        )}
      
      <p className="titulo-checkout2" onClick={handlePaymentFormToggle}>
          <p>OPCIONES DE PAGO</p> 
          <p>{paymentFormVisible ? '▼' : '▲'}</p>
      </p>

      {paymentFormVisible && (
        <div className='payment-checkout-column'>
          <label>Seleccione una opción de pago:</label>
          <select value={selectedPaymentOption} onChange={handlePaymentOptionChange}>
            <option value="">--</option>
            <option value="balance">Balance</option>
          </select>
        </div>
      )}

      </div>
      
      <div className="subtotal-column">
        <table className="subtotal-table">
          <thead>
            <tr>
              <th>PRODUCTO</th>
              <th>$ SUBTOTAL</th>
              <th>Bs. SUBTOTAL</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item, index) => (
              <tr key={index} className="cart-item">
                {item.tabName === 'molduras' ? (
                  <td>{item.brand} {item.catalogue} {item.product_description} {item.producttype} {item.name} {item.medida} x {item.quantity}</td>
                ) : (
                  <td>{item.brand} {item.catalogue} {item.name} {item.medida} x {item.quantity}</td>
                )}
                <td>{(item.price * item.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                <td>Bs. {(item.priceBs * item.quantity).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
            ))}
            <tr>
              <td className='row-table'>SUBTOTAL</td>
              <td>{totalDollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
              <td>Bs. {totalBs.toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className='row-table'>ENVÍO</td>
              <td>{shippingDollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
              <td>Bs. {shippingBs.toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className='row-table'>TOTAL</td>
              <td>{(totalDollars + shippingDollars).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
              <td>Bs. {(totalBs + shippingBs).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
          </tbody>
        </table>
        
        <button className="checkout2-button" onClick={placeOrder}>REALIZAR PEDIDO</button>
        {errorPlacingOrder && (
          <p className="error-message" style={{ textAlign: 'center', margin: '0 auto' }}>{errorPlacingOrder}</p>
        )}
      </div>

    </div>
  );
};

export default CheckoutStep2;

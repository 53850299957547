import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './OrderPlaced.css';

const OrderPlacedDetails = ({clearCart}) => {
  const location = useLocation();
  const [payment, setPayment] = useState([]);

  const baseURL = process.env.NODE_ENV === 'production' ? "https://ivyrevest.com/api/" : "http://localhost:5000/api/";

  const {
    state: {
      orderId,
      paymentMethod,
      billingDetails,
      shippingDetails,
      orderItems,
      totalDollars,
      totalBs,
      notes,
      shippingDollars,
      shippingBs,
      selectedEnvio,
      selectedFacturacion,
    },
  } = location;

  const fetchPayments = async () => {
    try {
      const response = await fetch(`${baseURL}paymentInfo/${encodeURIComponent(paymentMethod)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();;
      setPayment(data[0].info);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchPayments();
    clearCart();
  }, []);

  return (
    <div className="order-placed-details-container">
      
      <h1 className="order-placed-title">¡Gracias por su pedido!</h1>
      <p className="order-placed-message">
        Un correo de confirmación ha sido enviado a {billingDetails.email} con los detalles de su pedido.
      </p>

      <p className="order-id">Orden ID: {orderId}</p>
      <div className="order-details">
        <div className="payment-info">
          <h2 className="payment-heading">Información de Pago</h2>
          <div>
	  
	  <p className='informacion-pago-monto'>Monto en $: <strong>{(totalDollars + shippingDollars).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></p>
            <p className='informacion-pago-monto'>Monto en Bs.: <strong>Bs. {(totalBs + shippingBs).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></p>
            <p className='informacion-pago-details'>Pagado con balance de la cuenta</p>

          </div>
        </div>
      </div>

        <div className='resumen-de-orden'>
          <h2 className="payment-heading">Resumen de la orden</h2>
        </div>

        <div className='resumen-de-orden-container'>

          <div className="subtotal-column2">
            <table className="subtotal-table2">
              <thead>
                <tr>
                  <th>PRODUCTO</th>
                 
                    <th>$ SUBTOTAL</th>
                  
                  
                    <th>Bs. SUBTOTAL</th>
                  
                </tr>
              </thead>
              <tbody>
                {orderItems.map((item, index) => (
                  <tr key={index} className="cart-item">
                    {item.tabName === 'molduras' ? (
                      <td>{item.brand} {item.catalogue} {item.product_description} {item.producttype} {item.name} {item.medida} x {item.quantity}</td>
                    ) : (
                      <td>{item.brand} {item.catalogue} {item.name} {item.medida} x {item.quantity}</td>
                    )}
                    
                      <td>{(item.price * item.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                      <td>Bs. {(item.priceBs * item.quantity).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    
                  </tr>
                ))}
                <tr>
                  <td className='row-table2'>SUBTOTAL</td>
                  
                    <td>{totalDollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  
                    <td> Bs. {totalBs.toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  
                </tr>
                <tr>
                  <td className='row-table2'>ENVÍO</td>
                  
                    <td>{shippingDollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  
                    <td>Bs. {shippingBs.toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  
                </tr>
                <tr>
                  <td className='row-table2'>TOTAL</td>
                  
                    <td>{(totalDollars + shippingDollars).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  
                    <td>Bs. {(totalBs + shippingBs).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  
                </tr>
              </tbody>
            </table>
          </div>

          <div className='both-addresses'>

            <div className='facturacion-orderplaced'>
              <p><strong>Dirección de facturación:</strong></p>
              {selectedFacturacion === "Personal" && (
                <div>
                  <p className='billing-detail'>Nombre: {billingDetails.nombre}</p>
                  <p className='billing-detail'>Apellido: {billingDetails.apellidos}</p>
                  <p className='billing-detail'>Cédula: {billingDetails.cedula}</p>
                </div>
              )}
              {selectedFacturacion === "Jurídico" && (
                <div>
                  <p className='billing-detail'>Compañía: {billingDetails.company}</p>
                  <p className='billing-detail'>RIF: {billingDetails.rif}</p>
                </div>
              )}
              
              <p className='billing-detail'>Dirección: {billingDetails.direccion}, {billingDetails.estado}, {billingDetails.municipio}, {billingDetails.codigo}</p>
              <p className='billing-detail'>Teléfono: {billingDetails.telefono}</p>
              <p className='billing-detail'>Email: {billingDetails.email}</p>
            </div>

            <div className='shipping-orderplaced'>
              <p><strong>Dirección de envío:</strong></p>
              {selectedEnvio === "Personal" && (
                <div>
                  <p className='billing-detail'>Nombre: {shippingDetails.nombre}</p>
                  <p className='billing-detail'>Apellido: {shippingDetails.apellidos}</p>
                </div>
              )}

              {selectedEnvio === "Jurídico" && (
                <div>
                  <p className='billing-detail'>Compañía: {shippingDetails.company}</p>
                  <p className='billing-detail'>RIF: {shippingDetails.rif}</p>
                </div>
              )}
              
              <p className='billing-detail'>Dirección: {shippingDetails.direccion}, {shippingDetails.estado}, {shippingDetails.municipio}, {shippingDetails.codigo}</p>
              <p className='billing-detail'>Teléfono: {shippingDetails.telefono}</p>
              <p className='billing-detail'>Email: {shippingDetails.email}</p>
              <p className='billing-detail'>Notes: {notes}</p>
            </div>

          </div>

        </div>

    </div>
  );
};

export default OrderPlacedDetails;

import React, { useEffect, useState } from 'react';
import './Notification.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Notification = ({ message, onClose }) => {
  const [shouldClose, setShouldClose] = useState(false);
  const navigate = useNavigate();
  const [previousScrollY, setPreviousScrollY] = useState(0);

  const handleGoToCart = () => {
    navigate('/checkout');
    onClose();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldClose(true);
    }, 3000);
  
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > previousScrollY) {
        setShouldClose(true);
      }
      setPreviousScrollY(scrollY);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onClose, shouldClose, previousScrollY]);

  useEffect(() => {
    if (shouldClose) {
      onClose();
    }
  }, [shouldClose, onClose]);

  return (
    <div>
      <div className="notification-overlay" />
      <div className="notification-container">
        <div className='añadido-carrito'>
          <div className='check-added'>
            <FontAwesomeIcon className='check-icon' icon={faCheck} style={{ color: 'green', fontSize: '20px' }} />
            <div>Añadido al carrito</div>
          </div>
          <button className='notification-close-button' onClick={onClose}>&times;</button>
        </div>
        <div className='product-info-notification'>
          <div>
            {message.tabName === 'molduras' ? (
              <>{message.brand + ' ' + message.catalogue + ' ' + message.product_description + ' ' + message.producttype + ' ' +message.name} x {message.quantitySelected}</>
            ) : (
              <>{message.brand + ' ' + message.catalogue + ' ' + message.name} x {message.quantitySelected}</>
            )}
          </div>
          <div>{message.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {message.priceBs.toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </div>
        </div>
        <button className='go-to-cart-button' onClick={handleGoToCart}>Ver carrito</button>
      </div>
    </div>
  );
};

export default Notification;

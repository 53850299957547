import React, {useState} from "react";
import './Descuentos.css';

const Descuentos = ({balance}) => {
    
    const bajo = [0, 1000];
    const medio_bajo = [1001, 4000];
    const medio = [4001, 7000];
    const medio_alto = [7001, 10000];
    const alto = [10000];

    return (
        <div className='descuentos'>
            {balance == 0 && (
                <div className="subscribe">
                    <p><strong>Suscripción no activa</strong></p>
                    <p>Para tener accesibilidad a descuentos se debe suscribir y agregar balance</p>
                </div>
            )}

            {balance > 0 && (
                <div className="subscribe">
                    <table>
                        <thead>
                            <tr>
                                <th>Descuento</th>
                                <th>Balance necesario</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={balance<=bajo[1] ? "descuento-activo" : ""}>Bajo</td>
                                <td className={balance<=bajo[1] ? "descuento-activo" : ""}>{bajo[0].toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - {bajo[1].toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            </tr>
                            <tr>
                                <td className={balance>=medio_bajo[0] && balance <= medio_bajo[1] ? "descuento-activo" : ""}>Medio Bajo</td>
                                <td className={balance>=medio_bajo[0] && balance <= medio_bajo[1] ? "descuento-activo" : ""}>{medio_bajo[0].toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - {medio_bajo[1].toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            </tr>
                            <tr>
                                <td className={balance>=medio[0] && balance<=medio[1] ? "descuento-activo" : ""}>Medio</td>
                                <td className={balance>=medio[0] && balance<=medio[1] ? "descuento-activo" : ""}>{medio[0].toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - {medio[1].toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            </tr>
                            <tr>
                                <td className={balance>=medio_alto[0] && balance<=medio_alto[1] ? "descuento-activo" : ""}>Medio Alto</td>
                                <td className={balance>=medio_alto[0] && balance<=medio_alto[1] ? "descuento-activo" : ""}>{medio_alto[0].toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - {medio_alto[1].toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            </tr>
                            <tr>
                                <td className={balance>alto[0] ? "descuento-activo" : ""}>Alto</td>
                                <td className={balance>alto[0] ? "descuento-activo" : ""}>{alto[0].toLocaleString('en-US', { style: 'currency', currency: 'USD' })}+</td>
                            </tr>
                        </tbody>
                    </table>
                    <p style={{ color: 'green', marginTop: '40px' }}>Descuento activo acorde al balance en la cuenta</p>
                </div> 
            )}
        </div>
    );
};




export default Descuentos;

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './ProductDetails.css';
import { MdOutlineShoppingBag } from "react-icons/md";

const ProductDetails = ({ addToCart, exchange_rate, checkQuantityInCart, balance}) => {
  
  const { tabName, brandName, catalogueName, productName } = useParams();
  const { state } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [errorAddingCart, seterrorAddingCart] = useState('');
  const bajo = [0, 1000];
  const medio_bajo = [1001, 4000];
  const medio = [4001, 7000];
  const medio_alto = [7001, 10000];
  const alto = [10000];

  const baseURL = process.env.NODE_ENV === 'production' ? "https://ivyrevest.com/api/" : "http://localhost:5000/api/";
  
  const [selectedProduct, setselectedProduct] =  useState(state?.selectedProduct || null);
  const [product, setProduct] = useState({ images: [] });
  const [cantidadCarrito, setCantidadCarrito] = useState(0);

  const [selectedImage, setSelectedImage] = useState(
  `https://d27c390d45rh5k.cloudfront.net/images_dasher/${tabName}/${state?.selectedProduct.brand_name.toUpperCase()}/${state?.selectedProduct.catalogue_name}/${state?.selectedProduct.product_name}/image1.jpeg` || null
);

  const [quantity, setQuantity] = useState(0);

  const fetchProduct = async () => {
    try {
      const response = await fetch(`${baseURL}products/${encodeURIComponent(tabName)}/${encodeURIComponent(brandName)}/${encodeURIComponent(catalogueName)}/${encodeURIComponent(productName)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
    
      setselectedProduct(data[0]);

      setCantidadCarrito(checkQuantityInCart({
        name: data[0].product_name,
	brand: data[0].brand_name,
        catalogue: data[0].catalogue_name,
        medida: data[0].measurements[selectedIndex],
      }));

      // Generate image URLs based on the number of images
      const imageBaseURL = `https://d27c390d45rh5k.cloudfront.net/images_dasher/${tabName}/${data[0].brand_name.toUpperCase()}/${data[0].catalogue_name}/${data[0].product_name}/image`;
      
      const productImages = [];
      for (let i = 1; i <= data[0].numImages; i++) {
        productImages.push(`${imageBaseURL}${i}.jpeg`);
      }

      setProduct({ images: productImages });
      setSelectedImage(`https://d27c390d45rh5k.cloudfront.net/images_dasher/${tabName}/${data[0].brand_name.toUpperCase()}/${data[0].catalogue_name}/${data[0].product_name}/image1.jpeg`);
      
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  const handleAddToCart = () => {

    if (quantity <= 0) {
      seterrorAddingCart('Cantidad tiene que ser mayor a cero');
      return;
    }

    let discount_bracket = 'discount_price';

    if (balance > 0 && balance<=bajo[1]) {
      discount_bracket = 'descuento_bajo';
    } else if (balance>=medio_bajo[0] && balance <= medio_bajo[1]) {
      discount_bracket = 'descuento_mediobajo';
    } else if (balance>=medio[0] && balance<=medio[1]) {
      discount_bracket = 'descuento_medio';
    } else if (balance>=medio_alto[0] && balance<=medio_alto[1]) {
      discount_bracket = 'descuento_medioalto';
    } else if (balance>alto[0]) {
      discount_bracket = 'descuento_alto';
    }

    const isSuccess = addToCart({
        name: selectedProduct?.product_name,
        brand: selectedProduct?.brand_name,
        catalogue: selectedProduct?.catalogue_name,
	     product_description: tabName === 'molduras' ? selectedProduct?.product_description : '',
        producttype: tabName === 'molduras' ? selectedProduct?.producttype : '',
        price: Math.min(parseFloat(selectedProduct?.discount_price[selectedIndex]), parseFloat(selectedProduct?.[discount_bracket][selectedIndex])),
        priceBs: Math.min(parseFloat((selectedProduct?.discount_price[selectedIndex] * exchange_rate).toFixed(2)), parseFloat((selectedProduct?.[discount_bracket][selectedIndex] * exchange_rate).toFixed(2))),
       quantitySelected: quantity,
       medida: selectedProduct?.measurements[selectedIndex],
       quantityAvailable: selectedProduct?.quantities[selectedIndex],
       tabName: tabName,
        selectedIndex: selectedIndex,
	    discount_bracket: discount_bracket,
      });

    if (!isSuccess) {
      seterrorAddingCart(`La cantidad no puede ser mayor a  ${selectedProduct?.quantities[selectedIndex]}`);
    } else {
      seterrorAddingCart('');
      setCantidadCarrito(cantidadCarrito + quantity);
      setQuantity(0);
      window.scrollTo(0, 0); 
    }
  };

  const incrementQuantity = () => {
    setQuantity(prevQuantity => Math.min(prevQuantity + 1, selectedProduct.quantities[selectedIndex]-cantidadCarrito));
  };

  const decrementQuantity = () => {
    setQuantity(prevQuantity => Math.max(prevQuantity - 1, 0));
  };

  const handleIndex = (event) => {
    const selectedMeasurementIndex = event.target.selectedIndex;
    setSelectedIndex(selectedMeasurementIndex);
  };

  useEffect(() => {
    setCantidadCarrito(checkQuantityInCart({
      name: selectedProduct?.product_name,
      brand: selectedProduct?.brand_name,
      catalogue: selectedProduct?.catalogue_name,
      medida: selectedProduct?.measurements[selectedIndex],
    }));
  }, [selectedIndex, cantidadCarrito]);

  useEffect(() => {
    // scroll to top always 
    window.scrollTo(0, 0);
    if (selectedProduct == null) {
      console.log("calling fetch prod");
      fetchProduct();
    } else {

       // Generate image URLs based on the number of images
       const imageBaseURL = `https://d27c390d45rh5k.cloudfront.net/images_dasher/${tabName}/${selectedProduct.brand_name.toUpperCase()}/${selectedProduct.catalogue_name}/${selectedProduct.product_name}/image`;
      
       const productImages = [];
       for (let i = 1; i <= selectedProduct.numImages; i++) {
         productImages.push(`${imageBaseURL}${i}.jpeg`);
       }

      setProduct({ images: productImages });
      setCantidadCarrito(checkQuantityInCart({
        name: selectedProduct?.product_name,
	brand: selectedProduct?.brand_name,
      catalogue: selectedProduct?.catalogue_name,
        medida: selectedProduct?.measurements[selectedIndex],
      }));
    }
     
  }, [tabName, productName]);

  function renderPrice(selectedProduct) {
    if (selectedProduct.discount_price[selectedIndex] !== selectedProduct.price[selectedIndex]) {
      return (
        <div className='product-price-discount'>
          <p className='product-price-col' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(selectedProduct.discount_price[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / 
          Bs. {(selectedProduct.discount_price[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price-col-small' style={{textDecoration: 'line-through' }}>{parseFloat(selectedProduct.price[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} /
          Bs. {(selectedProduct.discount_price[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
      // descuento bajo
    } else if (balance<=bajo[1] && selectedProduct.price[selectedIndex] > selectedProduct.descuento_bajo[selectedIndex]) {
      return (
        <div className='product-price-discount'>
          <p className='product-price-col' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(selectedProduct.descuento_bajo[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / 
          Bs. {(selectedProduct.descuento_bajo[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price-col-small' style={{textDecoration: 'line-through' }}>{parseFloat(selectedProduct.price[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} /
          Bs. {(selectedProduct.price[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
      // descuento medio bajo
    } else if (balance>=medio_bajo[0] && balance <= medio_bajo[1] && selectedProduct.price[selectedIndex] > selectedProduct.descuento_mediobajo[selectedIndex]) {
      return (
        <div className='product-price-discount'>
          <p className='product-price-col' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(selectedProduct.descuento_mediobajo[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / 
          Bs. {(selectedProduct.descuento_mediobajo[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price-col-small' style={{textDecoration: 'line-through' }}>{parseFloat(selectedProduct.price[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} /
          Bs. {(selectedProduct.price[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
      // descuento medio
    } else if (balance>=medio[0] && balance<=medio[1] && selectedProduct.price[selectedIndex] > selectedProduct.descuento_medio[selectedIndex]) {
      return (
        <div className='product-price-discount'>
          <p className='product-price-col' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(selectedProduct.descuento_medio[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / 
          Bs. {(selectedProduct.descuento_medio[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price-col-small' style={{textDecoration: 'line-through' }}>{parseFloat(selectedProduct.price[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} /
          Bs. {(selectedProduct.price[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
      // descuento medio alto 
    } else if (balance>=medio_alto[0] && balance<=medio_alto[1] && selectedProduct.price[selectedIndex] > selectedProduct.descuento_medioalto[selectedIndex]) {
      return (
        <div className='product-price-discount'>
          <p className='product-price-col' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(selectedProduct.descuento_medioalto[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / 
          Bs. {(selectedProduct.descuento_medioalto[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price-col-small' style={{textDecoration: 'line-through' }}>{parseFloat(selectedProduct.price[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} /
          Bs. {(selectedProduct.price[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
      // descuento alto
    } else if (balance>alto[0] && selectedProduct.price[selectedIndex] > selectedProduct.descuento_alto[selectedIndex]) {
      return (
        <div className='product-price-discount'>
          <p className='product-price-col' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(selectedProduct.descuento_alto[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / 
          Bs. {(selectedProduct.descuento_alto[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price-col-small' style={{textDecoration: 'line-through' }}>{parseFloat(selectedProduct.price[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} /
          Bs. {(selectedProduct.price[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
      // no discount no balance
    } else {
      return (
        <p className='product-price-col'>
        {parseFloat(selectedProduct.price[selectedIndex]).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} /
         Bs. {(selectedProduct.discount_price[selectedIndex] * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </p>
      );
    }
  };
    
  return (
    <div className="product-details-content">
      {selectedProduct && product ? (
        <div className="columns-horizontal">
          <div className='images-thumbnail'>
            <div className="image-container" onClick={() => setSelectedImage(product.images[0])}>
              <img
                src={selectedImage}
                alt={selectedProduct.product_name}
                className="product-image"
              />
            </div>
  
            <div className="thumbnail-images">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${selectedImage === image ? 'selected' : ''}`}
                  onClick={() => handleThumbnailClick(image)}
                />
              ))}
            </div>
          </div>
  
          <div className="columns-vertical">
            <h1 className="product-title-col">
              {selectedProduct.product_description ? (
                  selectedProduct.brand_name + ' ' + selectedProduct.catalogue_name + ' ' + selectedProduct.product_description + ' ' + selectedProduct.producttype + ' ' + selectedProduct.product_name
                ): (
                  selectedProduct.brand_name + ' ' + selectedProduct.catalogue_name + ' ' + selectedProduct.product_name
                )}
              </h1>

             {renderPrice(selectedProduct)}
             
              {(balance > 0 && parseFloat(selectedProduct.price) === parseFloat(selectedProduct.discount_price) && (parseFloat(selectedProduct.price) > parseFloat(selectedProduct.descuento_bajo) || parseFloat(selectedProduct.price) > parseFloat(selectedProduct.descuento_mediobajo) || parseFloat(selectedProduct.price) > parseFloat(selectedProduct.descuento_medio) || parseFloat(selectedProduct.price) > parseFloat(selectedProduct.descuento_medioalto) || parseFloat(selectedProduct.price) > parseFloat(selectedProduct.descuento_alto))) && (
                <div className='product-discount-options'>
                  <p className={balance > 0 && balance <= bajo[1] ? "product-discount-options-active": 'product-discount-option'}>Descuento bajo: {selectedProduct.descuento_bajo.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(selectedProduct.descuento_bajo * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  <p className={balance>=medio_bajo[0] && balance <= medio_bajo[1] ? "product-discount-options-active": 'product-discount-option'}>Descuento medio bajo: {selectedProduct.descuento_mediobajo.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(selectedProduct.descuento_mediobajo * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  <p className={balance>=medio[0] && balance<=medio[1] ? "product-discount-options-active": 'product-discount-option'}>Descuento medio: {selectedProduct.descuento_medio.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(selectedProduct.descuento_medio * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  <p className={balance>=medio_alto[0] && balance<=medio_alto[1] ? "product-discount-options-active": 'product-discount-option'}>Descuento medio alto: {selectedProduct.descuento_medioalto.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(selectedProduct.descuento_medioalto * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  <p className={balance>alto[0] ? "product-discount-options-active": 'product-discount-option'}>Descuento alto: {selectedProduct.descuento_alto.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(selectedProduct.descuento_alto * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
              )}

            <div className="horizontal-line">
  
              <div className='medida-div'>
                <label> Medida: </label>
                <select id="measurement" className="custom-select" onChange={handleIndex}>
                  {selectedProduct.measurements.map((measurement, index) => (
                    <option key={index} value={measurement}>
                      {measurement}
                    </option>
                  ))}
                </select>
              </div>
  
              <p>
                Color:
                <span style={{ marginLeft: '7px' }}>
                  {selectedProduct.color}
                </span>
              </p>
  
            </div>
  
            <div className="quantity-controls">
              <span className="quantity-label">Cantidad:</span>
              <button disabled={quantity === 0} onClick={decrementQuantity}>-</button>
              <span className="quantity-display">{quantity}</span>
              <button disabled={quantity >= selectedProduct?.quantities[selectedIndex]-cantidadCarrito} onClick={incrementQuantity}>+</button>
            </div>
  
            <p className="available-col">{selectedProduct.quantities[selectedIndex]} disponibles</p>
	      {cantidadCarrito > 0 && (
              <div className="carrito-quantity-column">
                {cantidadCarrito} en el carrito
                <MdOutlineShoppingBag className='cart-image-prod'/>
              </div>
            )}
            
	      <div className='error-adding-cart'>
              <button disabled={quantity === 0} className="add-to-cart-button" onClick={handleAddToCart}>
                  AGREGAR AL CARRITO
                  <MdOutlineShoppingBag className='cart-image-prod'/>
                </button>
              {errorAddingCart && (
                <p className='error-message' style={{ textAlign: 'center', margin: '0 auto' }}>{errorAddingCart}</p>
              )}
            </div>

          </div>
        </div>
        
      ) : null }
  
      {selectedProduct && selectedProduct.details && (
        <div className='info-rectangle'>
          {selectedProduct.details}
        </div>
      )}
    </div>
  );
};
  

export default ProductDetails;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import Checkout from './pages/Checkout';
import Profile from './pages/Profile';
import DynamicPage from './pages/DynamicPage';
import ProductDetails from './pages/ProductDetails';
import CheckoutStep2 from './pages/CheckoutStep2';
import CheckoutStep1 from './pages/CheckoutStep1';
import OrderPlaced from './pages/OrderPlaced';
import PasswordReset from './pages/PasswordReset';
import { auth, db } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ref, set, get } from "firebase/database";
import ProfileAccount from './pages/ProfileAccount';
import Notification from './components/Notification';

function App() {
  const [activeTab, setActiveTab] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [user] = useAuthState(auth);
  const [notification, setNotification] = useState(null);
  const [balance, setBalance] = useState(0);
  const [vencimiento, setVencimiento] = useState('');

  const exchange_rate = 33.81; //https://www.bcv.org.ve

  const handleNotificationClose = () => {
    setNotification('');
  };

  const baseURL = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:5001/api/";

  const fetchSuscripcion = async () => {
    try {
      const response = await fetch(`${baseURL}suscripcion/${encodeURIComponent(user.uid)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const balance = await response.json();
      console.log(balance);
      setBalance(balance.balance);
      setVencimiento(balance.vencimiento);
      } catch (error) {
        console.error('Error fecthing balance');
      }
    };

  const clearCart = () => {
    setCartItems([]);
    updateCartInDatabase([]);
    updateCartInLocalStorage([]);
  };

  const totalCartItems = cartItems.reduce((total, item) => total + item.quantity, 0);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const updateCartInLocalStorage = (updatedCart) => {
    localStorage.setItem('cartItems', JSON.stringify(updatedCart));
  };

  useEffect(() => {
    const fetchCartData = async () => {
      let updatedCartItems = [];
  
      if (user) {
        // If the user is logged in, fetch cart data from Firebase
        const cartRef = ref(db, `users/${user.uid}/cartItems`);
        try {
          const snapshot = await get(cartRef);
          const cartData = snapshot.val();
          if (Array.isArray(cartData)) {
            updatedCartItems = cartData;
          }
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      } else {
        // If there's no user logged in, get cart data from local storage
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        updatedCartItems = storedCartItems;
      }
  
      const mergeFlag = localStorage.getItem('merge');
  
      if (user && (!mergeFlag || mergeFlag === 'false')) {
        // Merge data if user is logged in and merge flag is not set
        const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        localCartItems.forEach((localItem) => {
          const existingCartItem = updatedCartItems.find(
            (dbItem) => dbItem.name === localItem.name
          );
          if (!existingCartItem) {
            updatedCartItems.push(localItem);
          } else {
            existingCartItem.quantity = Math.min(localItem.quantity + existingCartItem.quantity, localItem.quantityAvailable);
          }
        });
        localStorage.setItem('merge', 'true');
      }
  
      setCartItems(updatedCartItems);
      updateCartInLocalStorage(updatedCartItems);
      updateCartInDatabase(updatedCartItems);
    };
  
    fetchCartData();
    fetchSuscripcion();
  }, [user]);

  
  const updateCartInDatabase = (updatedCart) => {
    if (user) {
      const dbRef = ref(db, `users/${user.uid}/cartItems`);
      set(dbRef, updatedCart);
    }
  };

  const addToCart = (product) => {
    const itemIndex = cartItems.findIndex((item) => item.name === product.name && item.medida === product.medida && item.brand === product.brand && item.catalogue === product.catalogue);
    const totalQuantityInCart = itemIndex !== -1 ? cartItems[itemIndex].quantity : 0;
  
    if (totalQuantityInCart + product.quantitySelected > product.quantityAvailable) {
      return false;
    }
  
    let updatedCartItems;
    if (itemIndex !== -1) {
      updatedCartItems = cartItems.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            quantity: item.quantity + product.quantitySelected,
          };
        }
        return item;
      });
    } else {
      updatedCartItems = [...cartItems, { ...product, quantity: product.quantitySelected }];
    }
  
    setCartItems(updatedCartItems);
    updateCartInDatabase(updatedCartItems);
    updateCartInLocalStorage(updatedCartItems);

    setNotification(product);

    return true;
  };

   const checkQuantityInCart = (product) => {
    const itemIndex = cartItems.findIndex((item) => item.name === product.name && item.medida === product.medida && item.brand === product.brand && item.catalogue === product.catalogue);
    const totalQuantityInCart = itemIndex !== -1 ? cartItems[itemIndex].quantity : 0;
    return totalQuantityInCart;
  };
  

  const increaseQuantity = (item) => {
    const itemIndex = cartItems.findIndex((cartItem) => cartItem.name === item.name);
    const totalQuantityInCart = itemIndex !== -1 ? cartItems[itemIndex].quantity : 0;
  
    if (totalQuantityInCart + 1 > item.quantityAvailable) {
      return false;
    }
  
    const updatedCartItems = cartItems.map((cartItem) => {
      if (cartItem.name === item.name && item.medida === cartItem.medida) {
        return { ...cartItem, quantity: cartItem.quantity + 1 };
      }
      return cartItem;
    });
    setCartItems(updatedCartItems);
    updateCartInDatabase(updatedCartItems);
    updateCartInLocalStorage(updatedCartItems);
    
    return true;
  };
  

  const decreaseQuantity = (item) => {
    if (item.quantity === 1) {
      removeFromCart(item);
    } else {
      const updatedCartItems = cartItems.map((cartItem) => {
        if (cartItem.name === item.name && cartItem.quantity > 1 && item.medida === cartItem.medida) {
          return { ...cartItem, quantity: cartItem.quantity - 1 };
        }
        return cartItem;
      });
      setCartItems(updatedCartItems);
      updateCartInDatabase(updatedCartItems);
      updateCartInLocalStorage(updatedCartItems);
    }
  };
  

  const removeFromCart = (itemToRemove) => {
    let removed = false;
    const updatedCartItems = cartItems.filter((item) => {
      if (!removed && item.name === itemToRemove.name && item.medida === itemToRemove.medida) {
        removed = true;
        return false; // false means the item will not be added to updated cart items
      }
      return true; // added
    });
  
    setCartItems(updatedCartItems);
    updateCartInDatabase(updatedCartItems);
    updateCartInLocalStorage(updatedCartItems);
  };
  

  return (
    <Router>
      <div>
        <Header activeTab={activeTab} onTabChange={handleTabChange} totalCartItems={totalCartItems}/>
        {notification && <Notification message={notification} onClose={handleNotificationClose}/>}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/checkout"
            element={<Checkout cartItems={cartItems} increaseQuantity={increaseQuantity} decreaseQuantity={decreaseQuantity} removeFromCart={removeFromCart} balance={balance}/>}
          />
          <Route path="/profile" element={<Profile cartItems={cartItems}/>} />
          <Route path="/:tabName" element={<DynamicPage exchange_rate={exchange_rate} balance={balance}/>} />
          <Route
            path="/:tabName/:brandName/:catalogueName/:productName"
            element={<ProductDetails addToCart={addToCart} exchange_rate={exchange_rate} checkQuantityInCart={checkQuantityInCart} balance={balance}/>}
          />
          <Route path="/checkout/checkoutStep1" element={<CheckoutStep1/>} />
          <Route path="/checkout/checkoutStep2" element={<CheckoutStep2 cartItems={cartItems} exchange_rate={exchange_rate} balance={balance}/>} />
          <Route path="/checkout/checkoutStep2/orderPlaced/:orderID" element={<OrderPlaced clearCart={clearCart} />}/>
          <Route exact path="/reset-password" element={<PasswordReset />} />
          <Route exact path="/profile-account" element={<ProfileAccount balance={balance} vencimiento={vencimiento}/>} />
	  <Route exact path="/search-results" element={<DynamicPage exchange_rate={exchange_rate} />} />
        </Routes>
        <ContactUs/>
      </div>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import './SearchIcon.css';
import { useNavigate } from 'react-router-dom';

import { IoMdSearch } from "react-icons/io";

const SearchIcon = () => {
  const [searchBarVisible, setsearchBarVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [productsResult, setProductsResult] = useState([]);
  const baseURL = process.env.NODE_ENV === 'production' ? "api/" : "http://localhost:5001/api/";
  const navigate = useNavigate();
  const [errorSearch, setErrorSearch] = useState('');

  const handleSearchIconClick = () => {
    setsearchBarVisible(!searchBarVisible);
  }

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchInputClose = () => {
    setSearchTerm('');
  };

  const searchDatabase = async () => {
    try {;
;      const response = await fetch(`${baseURL}search?searchQuery=${encodeURIComponent(searchTerm)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.length == 0) {
        setErrorSearch('Ningún producto disponible con los filtros seleccionados');
      }
      setProductsResult(data);
    } catch (error) {
      console.error('Error fetching search items', error);
    }
  }

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchDatabase();
    }
  };

  useEffect(() => {
    if (errorSearch !== '') {
      navigate('/search-results', {
        state: { productsResult, searchTerm }
      });
    }
    if (productsResult.length > 0) {
      navigate('/search-results', {
        state: { productsResult, searchTerm }
      });
    }
  }, [productsResult]);

  return (
    <div className='search-icon-container'>
      <div className='search-icon-display' onClick={handleSearchIconClick}>
        <IoMdSearch className='custom-icon-class' />
      </div>
      {searchBarVisible && (
        <div className='search-bar-container'>
        <input
          type='text'
          placeholder='Buscar...'
          maxLength={50}
          value={searchTerm}
          onChange={handleSearchInputChange}
          onKeyDown={handleEnterKeyPress}
        />

        <span className='close-icon' onClick={handleSearchInputClose}>
          &times;
        </span>
      </div>
      )}
    </div>
  );
};

export default SearchIcon;

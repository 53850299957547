import React from "react";
import './Informacion.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const Informacion = ({orders}) => {
    const [user] = useAuthState(auth);

    return (
        <div className='informacion'>

            {user && (
                <div className="user-info">
                    <p><strong>Nombre:</strong> {user.displayName}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                </div>
            )}

            {orders.length > 0 && (
                <div className="list-addresses">
                    <p><strong>Direcciones de envío</strong></p>
                    <ul>
                        {Array.from(new Set(orders.map(order => JSON.stringify(order.shipping_details))))
                        .map(shipping => JSON.parse(shipping))
                        .map(shipping => (
                            <li key={shipping.email}>
                                {shipping.nombre ?
                                `${shipping.nombre + ' '} ${shipping.apellidos}, ${shipping.cedula}`
                                :
                                `${shipping.company}, ${shipping.rif}`
                                }
                                <ul>
                                    <li>{shipping.direccion}, {shipping.municipio}, {shipping.estado}, {shipping.codigo}</li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <p><strong>Direcciones de facturación</strong></p>
                    <ul>
                        {Array.from(new Set(orders.map(order => JSON.stringify(order.billing_details))))
                        .map(billing => JSON.parse(billing))
                        .map(billing => (
                            <li key={billing.email}>
                                {billing.nombre ?
                                `${billing.nombre + ' '} ${billing.apellidos}, ${billing.cedula}`
                                :
                                `${billing.company}, ${billing.rif}`
                                }
                                <ul>
                                    <li>{billing.direccion}, {billing.municipio}, {billing.estado}, {billing.codigo}</li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

        </div>
    );
};

export default Informacion;

/* DynamicPage.js */
import React, { useState, useEffect } from 'react';
import SortByPrice from '../components/SortByPrice';
import SortByBrand from '../components/SortByBrand';
import ProductSection from '../components/ProductSection';
import { useParams, useLocation } from 'react-router-dom';
import './DynamicPage.css';

const DynamicPage = ({exchange_rate, balance}) => {
  const { tabName } = useParams();
  const location = useLocation();
  const { productsResult, searchTerm } = location.state || {};
  const [sortBy, setSortBy] = useState('precio-bajo-alto');
  const [selectedDiscount, setSelectedDiscount] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCatalogues, setSelectedCatalogues] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedMedidas, setSelectedMedidas] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [selectedProductType, setSelectedProductType] = useState([]);

  useEffect(() => {
    setCurrentPage(1);
    setSelectedDiscount(false);
    setSortBy('precio-bajo-alto');
    setNumProducts('1');
    setSelectedColors([]);
    setSelectedMedidas([]);
    setSelectedCatalogues([]);
    setSelectedBrands([]);
  }, [productsResult] );

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (savedScrollPosition !== null) {
      setTimeout(() => {
        window.scrollTo({ top: parseInt(savedScrollPosition, 10), behavior: 'auto' });
      }, 20);
    }
  }, []);


  const [currentPage, setCurrentPage] = useState(1);
  const [numProducts, setNumProducts] = useState('1');

  const tabNameMap = {
    alfombras: 'ALFOMBRAS',
    papeltapiz: 'PAPEL TAPIZ',
    alfombrasmodulares: 'ALFOMBRAS MODULARES',
    molduras: 'MOLDURAS',
  };

  // local storage sort details 
  useEffect(() => {
    const savedCurrentPage = sessionStorage.getItem(`currentPage_${tabName}`);
    if (savedCurrentPage) {
      setCurrentPage(parseInt(savedCurrentPage, 10));
    } else {
      setCurrentPage(1);
    }

    const savedDiscount = localStorage.getItem(`discount_${tabName}`);
    if (savedDiscount === "true") {
      setSelectedDiscount(true);
    } else {
      setSelectedDiscount(false);
    }

    const selectedSortBy = localStorage.getItem(`sortBy_${tabName}`);
    if (selectedSortBy) {
      setSortBy(selectedSortBy);
    } else {
      setSortBy('precio-bajo-alto');
    }
    const selectedNumProducts = localStorage.getItem(`productosPagina_${tabName}`);
    if (selectedNumProducts) {
      console.log("selectednumproducts", selectedNumProducts);
      setNumProducts(selectedNumProducts);
    } else {
      
      setNumProducts('1');
    }
    const selectedColors = JSON.parse(localStorage.getItem(`colors_${tabName}`));
    if (selectedColors) {
      setSelectedColors(selectedColors);
    } else {
      setSelectedColors([]);
    }
    const selectedMedidas = JSON.parse(localStorage.getItem(`medidas_${tabName}`));
    if (selectedMedidas) {
      setSelectedMedidas(selectedMedidas);
    } else {
      setSelectedMedidas([]);
    }
    const selectedCatalogues = JSON.parse(localStorage.getItem(`catalogues_${tabName}`));
    if (selectedCatalogues) {
      setSelectedCatalogues(selectedCatalogues);
    } else {
      setSelectedCatalogues([]);
    }
    const selectedBrands = JSON.parse(localStorage.getItem(`brands_${tabName}`));
    if (selectedBrands) {
      setSelectedBrands(selectedBrands);
    } else {
      setSelectedBrands([]);
    }
  }, [tabName]);

  const handleSortByChange = (selectedSortBy) => {
    setSortBy(selectedSortBy);
    localStorage.setItem(`sortBy_${tabName}`, selectedSortBy);
  };

  const handleNumProducts = (selectedNumProducts) => {
    setNumProducts(selectedNumProducts);
    localStorage.setItem(`productosPagina_${tabName}`, selectedNumProducts);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(selectedNumProducts);
  };

  // set all catalogues of the brand checked 
  const handleSelectedBrands = (brand_obj) => {
    const brand_name = brand_obj.brand_name;
    const catalogues_brand = brand_obj.catalogues.map(item => item.catalogue_name);

    let updatedSelectedBrands = [...selectedBrands];
    let updatedSelectedCatalogues = [...selectedCatalogues];
    
    if (updatedSelectedBrands.includes(brand_name)) {
      updatedSelectedBrands = updatedSelectedBrands.filter(brand => brand !== brand_name)
      updatedSelectedCatalogues = updatedSelectedCatalogues.filter(catalogue => !catalogues_brand.includes(catalogue));
    } else {
      updatedSelectedBrands = [...selectedBrands, brand_name];
      updatedSelectedCatalogues = [...updatedSelectedCatalogues, ...catalogues_brand];
    }
    setSelectedBrands(updatedSelectedBrands);
    setSelectedCatalogues(updatedSelectedCatalogues);
    localStorage.setItem(`catalogues_${tabName}`, JSON.stringify(updatedSelectedCatalogues));
    localStorage.setItem(`brands_${tabName}`, JSON.stringify(updatedSelectedBrands));
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(numProducts);
  }

  const handlesetSelectedCatalogues = (catalogue_obj) => {
    const catalogue_name = catalogue_obj.catalogue_name;
    const brand_name = catalogue_obj.brand_name;
 
    let updatedSelectedCatalogues = [...selectedCatalogues];
    let updatedSelectedBrands = [...selectedBrands];

    if (updatedSelectedCatalogues.includes(catalogue_name)) {
      updatedSelectedCatalogues = updatedSelectedCatalogues.filter(brand => brand !== catalogue_name)
      if (updatedSelectedBrands.includes(brand_name)) {
        updatedSelectedBrands = updatedSelectedBrands.filter(brand => brand !== brand_name)
        setSelectedBrands(updatedSelectedBrands);
      }
    } else {
      updatedSelectedCatalogues = [...selectedCatalogues, catalogue_name];
    }
    setSelectedCatalogues(updatedSelectedCatalogues);
    localStorage.setItem(`catalogues_${tabName}`, JSON.stringify(updatedSelectedCatalogues));
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(numProducts);
  }

  const handleSelectedColors = (color_name) => {
    let updatedSelectedColors = [...selectedColors];
    if (updatedSelectedColors.includes(color_name)) {
      updatedSelectedColors = updatedSelectedColors.filter((color) => color !== color_name)
    } else {
      updatedSelectedColors = [...updatedSelectedColors, color_name]
    }
    setSelectedColors(updatedSelectedColors);
    localStorage.setItem(`colors_${tabName}`, JSON.stringify(updatedSelectedColors));
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(numProducts);
  }

   const handleselectedProductType = (productType) => {
    let updatedSelectedProductType = [...selectedProductType];
    if (updatedSelectedProductType.includes(productType)) {
      updatedSelectedProductType = updatedSelectedProductType.filter((type) => type !== productType)
    } else {
      updatedSelectedProductType = [...updatedSelectedProductType, productType]
    }
    setSelectedProductType(updatedSelectedProductType);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(numProducts);
  }

   const handleSelectedDiscounts = (discount) => {
    setSelectedDiscount(discount);
    localStorage.setItem(`discount_${tabName}`, discount);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(numProducts);
  }

  const handleSelectedMedidas = (medida) => {
    let updatedSelectedMedidas = [...selectedMedidas];
    if (updatedSelectedMedidas.includes(medida)) {
      updatedSelectedMedidas = updatedSelectedMedidas.filter((med) => med !== medida)
    } else {
      updatedSelectedMedidas = [...updatedSelectedMedidas, medida]
    }
    setSelectedMedidas(updatedSelectedMedidas);
    localStorage.setItem(`medidas_${tabName}`, JSON.stringify(updatedSelectedMedidas));
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(numProducts);
  }

  return (
    <div className="main-content">
      <div className="tab-name">{tabNameMap[tabName] ? tabNameMap[tabName] : "Resultados de busqueda: " + searchTerm}</div>
      <div className="columns">
        <div className="brands-column">
         <SortByBrand 
            selectedBrands={selectedBrands} 
            handleSelectedBrands={handleSelectedBrands} 
            selectedCatalogues={selectedCatalogues} 
            handlesetSelectedCatalogues={handlesetSelectedCatalogues} 
            selectedColors={selectedColors} 
            handleSelectedColors={handleSelectedColors}
            selectedMedidas={selectedMedidas} 
            handleSelectedMedidas={handleSelectedMedidas} 
            selectedDiscount={selectedDiscount} 
            handleSelectedDiscounts={handleSelectedDiscounts}
	    selectedProductType={selectedProductType}
            handleselectedProductType={handleselectedProductType}
            productsResult={productsResult}
          />
        </div>
        <div className="products-column">
          <div className="sort-section">
            <SortByPrice
              sortBy={sortBy} 
              onSortByChange={handleSortByChange} 
              numProducts={numProducts} 
              onSelectNumProduct={handleNumProducts} 
              tabName={tabName}
            />
          </div>
          <ProductSection 
            sortBy={sortBy} 
            selectedBrands={selectedBrands} 
            selectedCatalogues={selectedCatalogues} 
            numProducts={numProducts} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            exchange_rate={exchange_rate} 
            selectedColors={selectedColors} 
            selectedMedidas={selectedMedidas} 
            startIndex={startIndex} 
            setStartIndex={setStartIndex}
            endIndex={endIndex} 
            setEndIndex={setEndIndex} 
            selectedDiscount={selectedDiscount}
	  selectedProductType={selectedProductType}
            productsResult={productsResult}
	    balance={balance}
          />
        </div>
      </div>
    </div>
  );
};

export default DynamicPage;

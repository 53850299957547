import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './SortByBrand.css';

const SortByBrand = ({selectedBrands, handleSelectedBrands, selectedCatalogues, handlesetSelectedCatalogues, selectedColors, handleSelectedColors, selectedMedidas, handleSelectedMedidas, selectedDiscount, handleSelectedDiscounts, productsResult, selectedProductType, handleselectedProductType}) => {
  const { tabName } = useParams();
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const productType = ['Cornisas', 'Molduras', 'Rodapies'];

  const brandCataloguesMap = new Map();

  if (productsResult) {
    productsResult.forEach((product) => {
      const {brand_id, brand_name, catalogue_id, catalogue_name} = product;

      if (!brandCataloguesMap.has(brand_id)) {
        brandCataloguesMap.set(brand_id, {brand_id, brand_name, catalogues: []});
      }

      const brandEntry = brandCataloguesMap.get(brand_id);

      if (!brandEntry.catalogues.some((catalogue) => catalogue.catalogue_id === catalogue_id)) {
        brandEntry.catalogues.push({catalogue_id, catalogue_name, brand_id, brand_name});
      }
    })
  }

  const colorSet = new Set();

  if (productsResult) {
    productsResult.forEach((product) => {
      const individualColors = product.color.split(',').map((color) => color.trim());
      individualColors.forEach((individualColor) => {
        colorSet.add(individualColor);
      });
    });
  }

  const medidasSet = new Set();

  if (productsResult) {
    productsResult.forEach((product) => {
      product.measurements.forEach((measurement) => {
        medidasSet.add(measurement);
      });
    });
  }

  const baseURL = process.env.NODE_ENV === 'production' ? "api/" : "http://localhost:5000/api/";

  const [isColorsSectionExpanded, setIsColorsSectionExpanded] = useState(true);
  const toggleColorsSection = () => {
    setIsColorsSectionExpanded((prevExpanded) => !prevExpanded);
  };

  const [isMedidasSectionExpanded, setIsMedidasSectionExpanded] = useState(true);
  const toggleMedidasSection = () => {
    setIsMedidasSectionExpanded((prevExpanded) => !prevExpanded);
  };

  const [isBrandsSectionExpanded, setIsBrandsSectionExpanded] = useState(true);
  const toggleBrandsSection = () => {
    setIsBrandsSectionExpanded((prevExpanded) => !prevExpanded);
  };

  const [isDescuentosSectionExpanded, setisDescuentosSectionExpanded] = useState(true);
  const toggleDescuentosSection = () => {
    setisDescuentosSectionExpanded((prevExpanded) => !prevExpanded);
  };

  const [isProductsSectionExpanded, setIsProductsSectionExpanded] = useState(true);
  const toggleProductsSection = () => {
    setIsProductsSectionExpanded((prevExpanded) => !prevExpanded);
  };

  const fetchBrands = async () => {
    const cacheKey = `allbrands_${tabName}`;
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    const currentTime = Date.now();
  
    if (cachedData && cachedTimestamp) {
      // Set the desired cache duration in milliseconds (e.g., 3 hours)
      const cacheDuration = 3 * 60 * 60 * 1000;
  
      if (currentTime - parseInt(cachedTimestamp, 10) < cacheDuration) {
        // The cached data is still within the desired cache duration, so use it
        setBrands(JSON.parse(cachedData));
        return;
      }
    }
  
    // Fetch new data if cached data is not available or has expired
    try {
      const response = await fetch(`${baseURL}brands/${encodeURIComponent(tabName)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
  
      // For each brand, fetch its catalogues and add them to the brand object
      const brandsWithCatalogues = await Promise.all(
        data.map(async (brand) => {
          const cataloguesResponse = await fetch(`${baseURL}catalogues/${brand.brand_id}`);
          if (!cataloguesResponse.ok) {
            throw new Error('Network response for catalogues was not ok');
          } 
          const cataloguesData = await cataloguesResponse.json();
          return { ...brand, catalogues: cataloguesData };
        })
      );
  
      setBrands(brandsWithCatalogues);
  
      // Update the cache
      localStorage.setItem(cacheKey, JSON.stringify(brandsWithCatalogues));
      localStorage.setItem(`${cacheKey}_timestamp`, currentTime);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };
  
  // Similar caching logic for fetchColors and fetchMedidas
  

  const fetchColors = async () => {

    const cacheKey = `allcolors_${tabName}`;
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    const currentTime = Date.now();
  
    if (cachedData && cachedTimestamp) {
      // Set the desired cache duration in milliseconds (e.g., 3 hours)
      const cacheDuration = 3 * 60 * 60 * 1000;
  
      if (currentTime - parseInt(cachedTimestamp, 10) < cacheDuration) {
        // The cached data is still within the desired cache duration, so use it
        setColors(JSON.parse(cachedData));
        return;
      }
    }

    try {
      const response = await fetch(`${baseURL}colores/${encodeURIComponent(tabName)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setColors(data[0].colores); 
      localStorage.setItem(cacheKey, JSON.stringify(data[0].colores));
      localStorage.setItem(`${cacheKey}_timestamp`, currentTime);
    } catch (error) {
      console.error('Error fetching colors:', error);
    }
  };

  const fetchMedidas = async() => {

    const cacheKey = `allmedidas_${tabName}`;
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    const currentTime = Date.now();
  
    if (cachedData && cachedTimestamp) {
      // Set the desired cache duration in milliseconds (e.g., 3 hours)
      const cacheDuration = 3 * 60 * 60 * 1000;
  
      if (currentTime - parseInt(cachedTimestamp, 10) < cacheDuration) {
        // The cached data is still within the desired cache duration, so use it
        setMedidas(JSON.parse(cachedData));
        return;
      }
    }

    try {
      const response = await fetch(`${baseURL}medidas/${encodeURIComponent(tabName)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMedidas(data[0].medidas);  
      localStorage.setItem(cacheKey, JSON.stringify(data[0].medidas));
      localStorage.setItem(`${cacheKey}_timestamp`, currentTime);
    } catch (error) {
      console.error('Error fetching colors:', error);
    }
  };

  useEffect(() => {
    if (typeof tabName === 'undefined') {
      setBrands([...brandCataloguesMap.values()]);
      setMedidas([...medidasSet]);
      setColors([...colorSet]);
    } else {
      fetchBrands();
      fetchColors();
      fetchMedidas();
    }
  }, [tabName, productsResult]);

  const mapSpanishColorToEnglish = (spanishColor) => {
    const colorMap = {
      'Amarillo': 'yellow',
      'Azul': 'blue',
      'Beige': '#F5DEB3',
      'Blanco': 'white',
      'Gris': 'gray',
      'Marrón': '#8B4513',
      'Naranja': 'orange',
      'Negro': 'black',
      'Rojo': 'red',
      'Verde': 'green',
      'Antracita': '#36454F',
      'Crema': '#FFFDD0',
      'Dorado': '#CFB53B',
      'Plateado': 'silver',
      'Rosado': 'pink',
      'Auber': '#A52A2A',
      'Choco': '#D2691E',
      'Casta': '#800000',
      'Tabak': '#5D3A1A',
      'Granite': '#605B56',
      'Crudo': '#FFFDD0'
    };
  
    return colorMap[spanishColor] || spanishColor;
  };
  
  return (
    <div className="brand-section">
      <h2 className="section-title">COMPRAR POR</h2>

      <p className="section-subtitle" onClick={toggleDescuentosSection}>
        <p>DESCUENTOS</p>
        <p>{isDescuentosSectionExpanded ? '▼' : '▲'}</p>
      </p>

      {isDescuentosSectionExpanded && (
        <div className="checkbox-column">
          <div className="brand-name">
          <label className='brand-name-checkbox'>
              <input
                type="checkbox"
                checked={selectedDiscount === 'true' || selectedDiscount === true}
                onChange={() => handleSelectedDiscounts(selectedDiscount === true ? false : true)}
              />
              Todos los descuentos
            </label>
            </div>
        </div>
      )}

      {tabName === 'molduras' && (
        <p className='section-subtitle' onClick={toggleProductsSection}>
          <p>PRODUCTOS</p>
          <p>{isProductsSectionExpanded ? '▼' : '▲'}</p>
        </p>
      )}

      {(isProductsSectionExpanded && tabName === 'molduras')  && (
          <div>
            {productType.map((productType, index) => (
              <div key={index} className="brand-name" style={{ marginBottom: '0.3rem' }}>
              <label className='brand-name-checkbox'>
              <input
                type="checkbox"
                name={productType}
                checked={selectedProductType.includes(productType)}  
                onChange={() => handleselectedProductType(productType)}       
              />
              {productType}
            </label>
            </div>
            ))}
          </div>
      )}

      <p className="section-subtitle" onClick={toggleBrandsSection}>
        <p>MARCAS Y CATÁLOGOS</p> 
        <p>{isBrandsSectionExpanded ? '▼' : '▲'}</p>
      </p>

      {isBrandsSectionExpanded && (
      <div className="checkbox-column">
        {brands.map((brandObj) => (
          <div key={brandObj.brand_id} className="brand-name">
            <label className='brand-name-checkbox'>
              <input
                type="checkbox"
                name={brandObj.brand_name}
                checked={selectedBrands.includes(brandObj.brand_name)}
                onChange={() => handleSelectedBrands(brandObj)}
              />
              {brandObj.brand_name}
            </label>
            <div className="catalogs-column">
              {brandObj.catalogues.map((catalog) => (
                <label key={catalog.catalogue_id}>
                  <input
                    type="checkbox"
                    name={catalog.catalogue_name}
                    checked={selectedCatalogues.includes(catalog.catalogue_name)}
                    onChange={() => handlesetSelectedCatalogues(catalog)}
                  />
                  {catalog.catalogue_name}
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
      )}

      <p className="section-subtitle" onClick={toggleColorsSection}>
        <p>COLORES</p> 
        <p>{isColorsSectionExpanded ? '▼' : '▲'}</p>
      </p>
    
      {isColorsSectionExpanded && (
    <div className='row-colors'>
      {colors.map((color, index) => (
        <div key={index} className='column-colors'>
          <button key={index} className='button-colors' style={{backgroundColor: mapSpanishColorToEnglish(color)}} onClick={() => handleSelectedColors(color)}>
            {selectedColors.includes(color) && (
              <div className='checkmark-colors' style={{color: color === 'Blanco' || color == 'Crudo' || color === 'Crema' || color === 'Amarillo' ? '#000' : '#fff'}}>
                &#10003;
              </div>
            )}
          </button>
          {color}
        </div>
      ))}
    </div>
    )}

      <p className="section-subtitle" onClick={toggleMedidasSection}>
        <p>MEDIDAS</p> 
        <p>{isMedidasSectionExpanded ? '▼' : '▲'}</p>
      </p>

      {isMedidasSectionExpanded && (
    <div>
    {medidas.map((medida, index) => (
        <div key={index} className="brand-name" style={{ marginBottom: '0.3rem' }}>
        <label className='brand-name-checkbox'>
        <input
          type="checkbox"
          name={medida}
          checked={selectedMedidas.includes(medida)}  
          onChange={() => handleSelectedMedidas(medida)}       
        />
        {medida}
      </label>
      </div>
      ))}
    </div>
      
      )}

    </div>
  );
};

export default SortByBrand;

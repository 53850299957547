import React, {useState} from "react";
import './Suscripcion.css';

const Suscripcion = ({balance, vencimiento, user}) => {
    
    const handleClick = () => {
        const email = 'ivyrevest@gmail.com';
        const subject = encodeURIComponent('Suscripción a Ivy Revest');
        const body = encodeURIComponent(`Hola, estoy interesado en suscribirme a Ivy Revest. Mis datos son:\n\nNombre de usuario: ${user.displayName}\nCorreo electrónico registrado: ${user.email}\nID de usuario: ${user.uid}\n\nPor favor, envíenme los detalles del pago para proceder.`);
        const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
        window.open(url, '_blank');
    };

    return (
        <div className='suscripcion'>
            {balance > 0 ? (
                <div className="suscription-info">
                    <p><strong>Status:</strong> Activa</p>
                    <p><strong>Expiración: </strong>{vencimiento}</p>
                </div>
            ) : (
                <div className="no-suscription">
                    <p><strong>Suscripción no activa</strong></p>
                    <button className="suscribirse-button" onClick={handleClick}>Suscribirse</button>
                </div>
            )}
        </div>
    );
};

export default Suscripcion;

import React, { useState, useEffect } from 'react';
import CartIcon from './CartIcon';
import ProfileIcon from './ProfileIcon';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import SearchIcon from './SearchIcon';

const Header = ({ activeTab, onTabChange, totalCartItems }) => {
  const [categories, setCategories] = useState([]);
  const [user] = useAuthState(auth);

  const baseURL = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:5000/api/";
 
  const navigate = useNavigate();

  const handleCartIconClick = () => {
    navigate('/checkout');
    onTabChange(null);
  };

  const handleProfileIconClick = () => {
    navigate('/profile');
    onTabChange(null);
  };

  const handleImageIconClick = () => {
    navigate('/');
    onTabChange(null);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${baseURL}categories`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);


  return (
<div className='column-header'>
	  <div className='row-profile' onClick={handleProfileIconClick}>

        <ProfileIcon user={user?.displayName }/>
        {user?.displayName ? (
          <p className='display-name'>Hola, {user.displayName}!</p>
        ) : <p className='display-name'>Iniciar Sesión</p>}

      </div>

    <header>
      
      <div onClick={handleImageIconClick} style={{ cursor: 'pointer' }}>
        <img src={'/images/logo.png'} alt="Logo" />
      </div>
      
      <nav>
        <ul>
          {categories.map((category) => {
            return (
              <li key={category.category_id} className={activeTab === category.category_name ? 'active' : ''}>
                <Link to={category.link} onClick={() => {
                  onTabChange(category.category_name);
                  sessionStorage.setItem('scrollPosition', window.scrollY);
             }}>
                  {category.category_name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>


      <div className="cart-profile-icons">
	 <div className='cart-icon-margin'>
          <SearchIcon/>
        </div>
        <div onClick={handleCartIconClick} className='cart-icon-margin'>
          <CartIcon totalItems={totalCartItems} />
        </div>
        </div>
      
    </header>
	  </div>
  );
};

export default Header;

// SortBySection.js
import React from 'react';
import './SortByPrice.css';

const SortByPrice = ({ sortBy, onSortByChange, numProducts, onSelectNumProduct, tabName }) => {

  const handleSortByChange = (event) => {
    const selectedSortBy = event.target.value;
    onSortByChange(selectedSortBy);
    localStorage.setItem(`sortBy_${tabName}`, selectedSortBy);
  };

  const handleNumProducts = (event) => {
    const selectedNumProducts = event.target.value;

    onSelectNumProduct(selectedNumProducts);
    localStorage.setItem(`productosPagina_${tabName}`, selectedNumProducts);
  };

  return (
    <div className="sort-by-section">

      <div className='column-smallscreen'>
      <span className="sort-by-label">Productos por página: </span>
        <div className="dropdown1">
          <select value={numProducts} onChange={handleNumProducts}>
            <option value={1}>Todos</option>
            <option value={16}>16</option>
            <option value={32}>32</option>
            <option value={64}>64</option>
          </select>
        </div>
      </div>
    

      <div className='column-smallscreen'>
      <span className="sort-by-label">Ordenar por: </span>
      <div className="dropdown">
        <select value={sortBy} onChange={handleSortByChange}>
          <option value="precio-bajo-alto">Precio: bajo-alto</option>
          <option value="precio-alto-bajo">Precio: alto-bajo</option>
          <option value="alf-a-z">Alfabéticamente: A-Z</option>
          <option value="alf-z-a">Alfabéticamente: Z-A</option>
        </select>
      </div>
    </div>
    </div>
  );
};

export default SortByPrice;

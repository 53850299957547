// CheckoutStep1.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CheckoutStep1.css';

const CheckoutStep1 = () => {
  const navigate = useNavigate();

  const handleGuestCheckout = () => {
    navigate('/checkout/checkoutStep2');
  };

  const handleLoginCheckout = () => {
    navigate('/profile?cameFromCheckoutStep1=true');
  };

  return (
    <div className='checkoutstep1-content'>
      <button className='checkout-button' onClick={handleGuestCheckout}>Realizar pedido como invitado</button>
      <button className='checkout-button' onClick={handleLoginCheckout}>Iniciar sesión o crear cuenta</button>
    </div>
  );
};

export default CheckoutStep1;

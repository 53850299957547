import React from "react";
import { useNavigate } from 'react-router-dom';
import "./Pedidos.css";

const Pedidos = ({ orders }) => {
  const exchange_rate = 33.81;
  const shippingDollars = parseFloat(5);
  const shippingBs = parseFloat(10 * exchange_rate);

  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate(`/${item.tabName}/${item.brand}/${item.catalogue}/${item.name}`);
  };

  return (
    <div className="orders-list">
      {orders.length > 0 ? (
        <ul className="list-orders">
          {orders.map((order) => (
            <li key={order.orderID} className="order-summary">
              <span className="order-id">Orden # {order.order_id}:</span>

                <ul>
                  <table className="item-pedidos">
                    <thead>
                      <tr>
                        <th>PRODUCTO</th>
                        <th>MEDIDA</th>
                        <th>PRECIO</th>
                        <th>CANTIDAD</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.cart_items.map((item, index) => (
                        <tr key={index}>
                          <td className="product-name-table" onClick={() => handleClick(item)}>{item.brand} {item.catalogue} {item.name}</td>
                          <td>{item.medida}</td>
                          <td>
                            {item.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td>{item.quantity}</td>
                          <td>
                            {(item.price * item.quantity).toLocaleString(
                              "en-US",
                              { style: "currency", currency: "USD" }
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ul>

              <ul>
                Dirección de envío:
                <ul>
                  <li>
                    {order.shipping_details.nombre
                      ? `${order.shipping_details.nombre + " "} ${
                          order.shipping_details.apellidos
                        }, ${order.billing_details.cedula}, ${
                          order.shipping_details.email
                        }`
                      : `${order.shipping_details.company}, ${
                          order.billing_details.rif
                        }, ${order.shipping_details.email}`}
                  </li>
                  <li>
                    {order.shipping_details.direccion},{" "}
                    {order.shipping_details.municipio},{" "}
                    {order.shipping_details.estado},{" "}
                    {order.shipping_details.codigo}
                  </li>
                </ul>
              </ul>

              <div className="total-price">
                Total:{" "}
                {(
                  parseFloat(order.totaldollars) + shippingDollars
                ).toLocaleString("en-US", { style: "currency", currency: "USD" })}{" "}
                / Bs.{" "}
                {(
                  parseFloat(order.totalbs) + shippingBs
                ).toLocaleString("es-VE", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>

              <ul>Pago: {order.payment_option}</ul>
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-order-summary">
          <p>Ninguna orden ha sido realizada</p>
        </div>
      )}
    </div>
  );
};

export default Pedidos;

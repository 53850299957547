import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ProductSection.css';
 
const ProductSection = ({ sortBy, selectedBrands, selectedCatalogues, numProducts, currentPage, setCurrentPage, exchange_rate, selectedColors, selectedMedidas, startIndex, setStartIndex, endIndex, setEndIndex, selectedDiscount, productsResult, selectedProductType, balance}) => {
  const { tabName } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const bajo = [0, 1000];
  const medio_bajo = [1001, 4000];
  const medio = [4001, 7000];
  const medio_alto = [7001, 10000];
  const alto = [10000];

  const baseURL = process.env.NODE_ENV === 'production' ? "api/" : "http://localhost:5000/api/";

  const fetchProducts = async () => {
    // Check if data is available in local storage
    const cachedData = localStorage.getItem(`productData_${tabName}`);
    const cachedTimestamp = localStorage.getItem(`productData_${tabName}_timestamp`);
    const currentTime = Date.now();
    
    if (cachedData && cachedTimestamp) {
      // Set the desired cache duration in milliseconds -> 3 hr
      const cacheDuration = 60 * 60 * 3000;
  
      if (currentTime - parseInt(cachedTimestamp, 10) < cacheDuration) {
        // The cached data is still within the desired cache duration
        setProducts(JSON.parse(cachedData));
        return;
      }
    } 

    try {
      const response = await fetch(`${baseURL}products/${encodeURIComponent(tabName)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      
      setProducts(data);
  
      // Store data in local storage with an expiration time
      localStorage.setItem(`productData_${tabName}`, JSON.stringify(data));
      localStorage.setItem(`productData_${tabName}_timestamp`, currentTime);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };
  
  
  useEffect(() => {
    if (tabName) {
       fetchProducts();
    } else {
       setProducts(productsResult);
    }
  }, [tabName, productsResult]);

  const handleProductClick = (product) => {   
    sessionStorage.setItem('scrollPosition', window.scrollY);
    if (typeof tabName === 'undefined') {
	    navigate(`/${encodeURIComponent(product.product_type)}/${encodeURIComponent(product.brand_name)}/${encodeURIComponent(product.catalogue_name)}/${encodeURIComponent(product.product_name)}`);
    } else {
	   navigate(`/${tabName}/${encodeURIComponent(product.brand_name)}/${encodeURIComponent(product.catalogue_name)}/${encodeURIComponent(product.product_name)}`, {
        state: { selectedProduct: product }
      });
    }
  };


  const filterProductsByBrand = (products, selectedBrands, selectedCatalogues) => {
    if (selectedBrands.length === 0 && selectedCatalogues.length === 0) {
      return products;
    }
    if (selectedBrands.length !== 0) {
      products = products.filter((product) => selectedBrands.includes(product.brand_name));
    }
    if (selectedCatalogues.length !== 0) {
      products = products.filter((product) => selectedCatalogues.includes(product.catalogue_name));
    }
    return products;
  };

  const filterProductsByColoresMedidas = (products, selectedColors, selectedMedidas) => {
    if (selectedColors.length === 0 && selectedMedidas.length === 0) {
      return products;
    }
    if (selectedColors.length !== 0) {
      products = products.filter((product) => {
        const productString = product.color.toLowerCase();
        const colorsMatch = selectedColors.some((color) => productString.includes(color.toLowerCase()));
        return colorsMatch;
      })
      
    }
    if (selectedMedidas.length !== 0) {
      products = products.filter((product) => {
        const medidasMatch = selectedMedidas.some((selectedMedida) =>
          product.measurements.some((medida) => medida.toLowerCase().includes(selectedMedida.toLowerCase()))
      );
      return medidasMatch;
      })
    }

    return products;
  };

  const filterProductsByDiscount = (products, selectedDiscount) => {
    if (selectedDiscount) {
      return products.filter(product => product.price[0] != product.discount_price[0] || (balance > 0 && (product.price[0] != product.descuento_bajo[0] ||
        product.price[0] != product.descuento_mediobajo[0] || product.price[0] != product.descuento_medio[0] || product.price[0] != product.descuento_medioalto[0] ||
        product.price[0] != product.descuento_alto[0])));
    }
    return products;
  }

  const sortProducts = (products, sortBy) => {
    const completeProductsName = products.map(product => ({
      ...product,
      name: `${product.brand_name} ${product.catalogue_name} ${product.product_name}`
    }));

    let discount_bracket = 'price';

    // compute corresponding discount bracket
    if (balance > 0 && balance<=bajo[1]) {
      discount_bracket = 'descuento_bajo';
    } else if (balance>=medio_bajo[0] && balance <= medio_bajo[1]) {
      discount_bracket = 'descuento_mediobajo';
    } else if (balance>=medio[0] && balance<=medio[1]) {
      discount_bracket = 'descuento_medio';
    } else if (balance>=medio_alto[0] && balance<=medio_alto[1]) {
      discount_bracket = 'descuento_medioalto';
    } else if (balance>alto[0]) {
      discount_bracket = 'descuento_alto';
    }

    if (sortBy === 'precio-bajo-alto') {
      return [...products].sort((a, b) => Math.min(a[discount_bracket][0], a.discount_price[0]) - Math.min(b[discount_bracket][0], b.discount_price[0]));
    } else if (sortBy === 'precio-alto-bajo') {
      return [...products].sort((a, b) => Math.min(b[discount_bracket][0], b.discount_price[0]) - Math.min(a[discount_bracket][0], a.discount_price[0]));
    } else if (sortBy === 'alf-a-z') {
        return [...completeProductsName].sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortBy === 'alf-z-a') {
      return [...completeProductsName].sort((a, b) => b.name.localeCompare(a.name));
    }
    return products;
  };
  
  const filteredProducts = filterProductsByBrand(products, selectedBrands, selectedCatalogues);
  const filteredProducts2 = filterProductsByColoresMedidas(filteredProducts, selectedColors, selectedMedidas);
  let filteredProducts3 = filterProductsByDiscount(filteredProducts2, selectedDiscount);
  // filter by product type if molduras giving selectedProductType
  if (tabName === 'molduras') {
    if (selectedProductType.length > 0) {
      const filteredProducts4 = filteredProducts3.filter(product => selectedProductType.includes(product.producttype));
      filteredProducts3 = filteredProducts4; 
    }
  }
  const sortedProducts = sortProducts(filteredProducts3, sortBy);
  
  
  if (numProducts === '1') {
    console.log("numpu", numProducts);
    numProducts = sortedProducts.length;
  }

  const totalPages = Math.ceil(sortedProducts.length / numProducts);

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    
    setTimeout(() => {
      sessionStorage.setItem(`currentPage_${tabName}`, (currentPage-1).toString());
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
    
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
     
    }
    
    setTimeout(() => {
      sessionStorage.setItem(`currentPage_${tabName}`, (currentPage+1).toString());
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  };

  useEffect(() => {
    if (startIndex === '0' && endIndex === String(numProducts)) {
      startIndex = 0;
      endIndex = numProducts;
    }
  }, [currentPage, startIndex, endIndex]);
  
  useEffect(() => {
    const newStartIndex = parseInt(currentPage-1, 10) * parseInt(numProducts, 10);
    const newEndIndex = Math.min(newStartIndex + parseInt(numProducts, 10), sortedProducts.length);
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, [currentPage, numProducts, sortedProducts]);

  const imageUrlsToPreload = sortedProducts
  .slice(startIndex, endIndex)
  .map((product) => {
    const brandNameInCaps = product.brand_name.toUpperCase(); 
    
    let imageUrl;
    if (typeof tabName === 'undefined') {
	    imageUrl = `https://d27c390d45rh5k.cloudfront.net/images_dasher/${product.product_type}/${brandNameInCaps}/${product.catalogue_name}/${product.product_name}/image2_v2.jpeg`;
    } else {
	    imageUrl = `https://d27c390d45rh5k.cloudfront.net/images_dasher/${tabName}/${brandNameInCaps}/${product.catalogue_name}/${product.product_name}/image2_v2.jpeg`;
    }
    const image = new Image();
    image.src = imageUrl;
    image.width = 220; 
    image.height = 270; 

    return image;
  });

  function renderPrice(product) {
    console.log("rendering price", balance);
    if (parseFloat(product.discount_price) !== parseFloat(product.price)) {
      return (
        <div className='product-price-discount'>
          <p className='product-price' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(product.discount_price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.discount_price) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price' style={{textDecoration: 'line-through', marginTop: 2 }}>{parseFloat(product.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.price) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
    // descuento bajo 
    } else if (balance > 0 && balance<=bajo[1] && parseFloat(product.price) > parseFloat(product.descuento_bajo)) {
      return (
        <div className='product-price-discount'>
          <p className='product-price' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(product.descuento_bajo).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.descuento_bajo) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price' style={{textDecoration: 'line-through', marginTop: 2 }}>{parseFloat(product.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.price) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
    // descuento medio bajo
    } else if (balance>=medio_bajo[0] && balance <= medio_bajo[1] && parseFloat(product.price) > parseFloat(product.descuento_mediobajo)) {
      return (
        <div className='product-price-discount'>
          <p className='product-price' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(product.descuento_mediobajo).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.descuento_mediobajo) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price' style={{textDecoration: 'line-through', marginTop: 2 }}>{parseFloat(product.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.price) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
    // descuento medio
    } else if (balance>=medio[0] && balance<=medio[1] && parseFloat(product.price) > parseFloat(product.descuento_medio)) {
      return (
        <div className='product-price-discount'>
          <p className='product-price' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(product.descuento_medio).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.descuento_medio) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price' style={{textDecoration: 'line-through', marginTop: 2 }}>{parseFloat(product.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.price) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
    // descuento medio alto
    } else if (balance>=medio_alto[0] && balance<=medio_alto[1] && parseFloat(product.price) > parseFloat(product.descuento_medioalto)) {
      return (
        <div className='product-price-discount'>
          <p className='product-price' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(product.descuento_medioalto).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.descuento_medioalto) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price' style={{textDecoration: 'line-through', marginTop: 2 }}>{parseFloat(product.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.price) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
      // descuento alto
    } else if (balance>alto[0] && parseFloat(product.price) > parseFloat(product.descuento_alto)) {
      return (
        <div className='product-price-discount'>
          <p className='product-price' style={{ color: 'red', marginBottom: '0px' }}>{parseFloat(product.descuento_alto).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.descuento_alto) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          <p className='product-price' style={{textDecoration: 'line-through', marginTop: 2 }}>{parseFloat(product.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / Bs. {(parseFloat(product.price) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
      );
      // no balance and no discount
    } else {
      return (
        <p className='product-price'>
        {parseFloat(product.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / 
          Bs. {(parseFloat(product.discount_price) * exchange_rate).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </p>
      );
    }
  };

  return (
    <div className='product-section-content'>
       {sortedProducts.length === 0 && (
          <p className='ningun-producto-descuento'>Ningún producto disponible con los filtros seleccionados</p>
        )}
      <div className="product-section">
        {sortedProducts.slice(startIndex, endIndex).map((product, index) => (
          <div key={product.product_id} className="product-card">
          <div className="product-image-container">
           <img src={imageUrlsToPreload[index].src} alt="Your Image" loading="lazy" onClick={() => handleProductClick(product)} className="product-image" width="220" height="270" async/>
          </div>
            <h3 className="product-name" onClick={() => handleProductClick(product)}>
              {product.product_description ? (
                product.brand_name + " " + product.catalogue_name + " " + product.product_description + " " + product.producttype
              ): (
                product.brand_name + " " + product.catalogue_name
              )}
            </h3>
            <p className='product-name-number'>{product.product_name}</p>
           
            {renderPrice(product)}
          </div>
        ))}
      </div>
  
      <div className="pagination">
        <button onClick={previousPage} disabled={currentPage === 1}>
          {"<<<"}
        </button>
	  {totalPages > 0 ? (
            <span>Página {currentPage} de {totalPages}</span>
        ) : (
            <span>Página 0 de 0</span>
        )}
        <button onClick={nextPage} disabled={endIndex >= sortedProducts.length}>
          {">>>"}
        </button>
      </div>

    </div>
  );
};  

export default ProductSection;


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAW3iqhHW61cT6StbQ06qd-P697NvO754U",
  authDomain: "dasher-b1369.firebaseapp.com",
  projectId: "dasher-b1369",
  storageBucket: "dasher-b1369.appspot.com",
  messagingSenderId: "924195522730",
  appId: "1:924195522730:web:276e800d41b435ea38a8d3",
  measurementId: "G-TGCE7G667W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Auth object
const auth = getAuth(app);

const db = getDatabase(app);

export { auth, db };



import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import './PasswordReset.css';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetError, setResetError] = useState(null);

  const mapFirebaseErrorToCustomMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'Correo no registrado';
      case 'auth/missing-email':
        return 'Por favor introducir correo electrónico';
      default:
        return 'Ocurrió un error';
    }
  };

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetEmailSent(true);
      })
      .catch((error) => {
        const customErrorMessage = mapFirebaseErrorToCustomMessage(error.code);
        setResetError(customErrorMessage);
      });
  };

  return (
    <div className='forgotpass-container'>
      {resetEmailSent ? (
        <div className='forgotpass-column'>
            <p>Un correo ha sido enviado a {email} con un enlace para restablecer contraseña.</p>
        </div>
      ) : (
        <div className='forgotpass-column'>
          <h2>Contraseña olvidada</h2>
          <p>Por favor introducir correo electrónico. Recibirás un enlace para crear una contraseña nueva por correo electrónico.</p>
          <div className="forgotpass-col">
            <input
                type="email"
                placeholder="Correo"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <button className='forgotpass-button' onClick={handleResetPassword}>Restablecer contraseña</button>
            {resetError && <p className="error-message">{resetError}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;

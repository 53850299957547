import React from 'react';
import './ContactUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const ContactUs = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    const height = document.documentElement.scrollHeight;
    window.scrollTo({
      top: height,
      behavior: 'smooth',
    });
  };
  
  return (
    <div className="contact-us-container">
      <h2>Contáctanos!</h2>
      <ul>
        <li>Email: ivyrevest@gmail.com</li>
        <li>Teléfono: +1 (404)341-8561</li>
        <li className='whatsapp-link-li'>
          <a className='whatsapp-link' href={`https://wa.me/${14043418561}`} target="_blank" rel="noreferrer">
            Envíanos un mensaje de WhatsApp
          </a>
          <a className="whatsapp-link" href="https://mail.google.com/mail/?view=cm&fs=1&to=ivyrevest@gmail.com" target="_blank" rel="noreferrer">
            Envíanos un correo
          </a>
        </li>
      </ul>
      <a href="https://api.whatsapp.com/send?phone=14043418561" className="float" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} className="my-float" />
      </a>
      <a className='float2'onClick={scrollToTop}>
        <FontAwesomeIcon icon={faArrowUp} className="my-float1" />
      </a>
      <a className="float1" onClick={scrollToBottom}>
          <FontAwesomeIcon icon={faArrowDown} className="my-float1" />
      </a>
    </div>
  );
};

export default ContactUs;

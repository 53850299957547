import React, { useState, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import './ProfileAccount.css';
import Pedidos from '../components/Pedidos';
import Informacion from '../components/Informacion';
import Suscripcion from '../components/Suscripcion';
import Descuentos from '../components/Descuentos';
import Balance from '../components/Balance';

const ProfileAccount = ({balance, vencimiento}) => {
    const navigate = useNavigate();
    const [user] = useAuthState(auth);
    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState('information');
    
    const baseURL = process.env.NODE_ENV === 'production' ? "api/" : "http://localhost:5001/api/";

  
    const handleSignOut = () => {
      signOut(auth)
        .then(() => {
        localStorage.clear();
        navigate('/profile');
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
    };

    const fetchOrders = async () => {
      try {
        const token = user && await user.getIdToken();
        const headers = token ? {authToken: token} : {};
        const response = await fetch(`${baseURL}getOrders/user/${encodeURIComponent(user.uid)}`, {
          headers: headers
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const orders = await response.json();
        setOrders(orders);
        
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    useEffect(() => {
      if (user) {
        fetchOrders();
      }
    }, [user]);
    
    
  return (
    <div>
      <div className="main-content-profile">
      {/* <div className="tab-name">PERFIL</div> */}
      <div className='profile-header'>
        <div>
          <div className="profile-account">Cuenta de Perfil</div>
          {user && (
            <div className="profile-name">{user.displayName}</div>
          )}
        </div>
        
        {/* <div className="profile-account">Cuenta de Perfil</div> */}
        <button className='sign-out-button' onClick={handleSignOut}>Cerrar sesión</button>

      </div>

      <div className="tabs">
        <p className={activeTab == 'information' ? 'active-tab': 'tab'} onClick={() => setActiveTab('information')}>Información</p>
        <p className={activeTab == 'pedidos' ? 'active-tab': 'tab'} onClick={() => setActiveTab('pedidos')}>Pedidos</p>
        <p className={activeTab == 'subscription' ? 'active-tab': 'tab'} onClick={() => setActiveTab('subscription')}>Suscripción</p>
        <p className={activeTab == 'descuentos' ? 'active-tab': 'tab'} onClick={() => setActiveTab('descuentos')}>Descuentos</p>
        <p className={activeTab == 'balance' ? 'active-tab': 'tab'} onClick={() => setActiveTab('balance')}>Balance</p>
      </div>

     {activeTab === 'information' && (
        <Informacion orders={orders}/>
      )}

      {activeTab === 'pedidos' && (
        <Pedidos orders={orders}/>
      )}

      {activeTab === 'subscription' && (
        <Suscripcion balance={balance} vencimiento={vencimiento} user={user}/>
      )}

      {activeTab === 'descuentos' && (
        <Descuentos balance={balance}/>
      )}

      {activeTab === 'balance' && (
        <Balance balance={balance} user={user}/>
      )}

        {/* <button className='sign-out-button' style={{marginTop: "10rem", marginBottom: "10rem"}} onClick={handleSignOut}>Cerrar sesión</button> */}
      </div>
    </div>
  );
};

export default ProfileAccount;


import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, db } from '../firebase';
import './Profile.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { ref, set } from "firebase/database";

const Profile  = ({ cartItems }) => {
  const [user] = useAuthState(auth);
  const [loginError, setLoginError] = useState(null);
  const [signupError, setsignupError] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();

  const [showPasswordLogin, setShowPasswordLogin] = useState(false);

  const handleTogglePasswordLogin = () => {
    setShowPasswordLogin(!showPasswordLogin);
  };

  const [showPasswordSignup, setShowPasswordSignup] = useState(false);

  const handleTogglePasswordSignup = () => {
    setShowPasswordSignup(!showPasswordSignup);
  };

  useEffect(() => {
    const cameFromCheckoutStep1 = new URLSearchParams(location.search).get('cameFromCheckoutStep1');

    if (cameFromCheckoutStep1 === 'true' && user) {
      sessionStorage.removeItem('cameFromCheckoutStep1');
      navigate('/checkout');
    } else if (user) {
      navigate('/profile-account');
    }
    
  }, [user, navigate, location]);

  const handleResetPassword = () => {
    navigate('/reset-password');
  };

  const mapFirebaseErrorToCustomMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'Correo no registrado';
      case 'auth/wrong-password':
        return 'Contraseña incorrecta';
      case 'auth/email-already-in-use':
        return 'Correo ya registrado';
     case 'auth/weak-password':
        return 'La contraseña no cumple los requisitos. Debe tener un mínimo de 8 caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.';
     default:
        return 'Ocurrió un error';
    }
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const isPasswordValid = (password) => {
    // Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&<>])[A-Za-z\d@$!%*?&<>]{8,}$/;
    return passwordPattern.test(password);
  };

  const isValidName = (name) => {
    const regex = /^[a-zA-Z\u00C0-\u017F\s']+$/u;
    return regex.test(name);
  };

  const handleEmailPasswordLogin = async (email, password) => {
    if (!isEmailValid(email)) {
      setLoginError('Correo electrónico inválido');
      setsignupError(null);
      return;
    }
    
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoginError(null);
      setsignupError(null);

      auth.currentUser.getIdToken(true);


      const cameFromCheckoutStep1 = sessionStorage.getItem('cameFromCheckoutStep1');
      if (cameFromCheckoutStep1 === 'true') {
        sessionStorage.removeItem('cameFromCheckoutStep1');
        navigate('/checkout');
      }
      
    } catch (error) {
      const customErrorMessage = mapFirebaseErrorToCustomMessage(error.code);
      setLoginError(customErrorMessage);
      setsignupError(null);
    }
  };
  
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
  
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        auth.currentUser.getIdToken(true);
      })
      .catch((error) => {
        console.error('Google Sign-In Error:', error);
      });
  };

  const handleSignUp = async (email, password, confirmPassword, name) => {
    if (!isEmailValid(email)) {
      setsignupError('Correo inválido');
      setLoginError(null);
      return;
    }

    if (!isValidName(name)) {
      setsignupError('Nombre inválido. Por favor ingrese solo letras y espacios.');
      setLoginError(null);
      return;
    }
    
    if (password != confirmPassword) {
      setsignupError('Las contraseñas no son iguales');
      setLoginError(null);
      return;
    }

    if (!isPasswordValid(password)) {
      setsignupError('La contraseña no cumple los requisitos. Debe tener un mínimo de 8 caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.');
      setLoginError(null);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      updateProfile(auth.currentUser, {
        displayName: String(name)
      });

      auth.currentUser.getIdToken(true);
  
      // Write to db
      set(ref(db, `users/${user.uid}`), {
        email:email,
        cartItems: cartItems,
      });
  
      setsignupError(null);
      setLoginError(null);
    } catch (error) {
      const customErrorMessage = mapFirebaseErrorToCustomMessage(error.code);
      setsignupError(customErrorMessage);
      setLoginError(null);
    }
  };

  return (
    <div className='login-signup'>
      
         <div className='content-loginsignup'>
            
            <div className='login-column'>
              <h2>Iniciar sesión</h2>

                <form className='form-container' onSubmit={(e) => {
                  e.preventDefault();
                  const email = e.target.email.value;
                  const password = e.target.password.value;
                  handleEmailPasswordLogin(email, password);
                }}>
                  <div className="form-column-login">
                  <input type="email" name="email" placeholder="Correo" required />
                  <input
                    type={showPasswordLogin ? 'text' : 'password'}
                    name="password"
                    placeholder="Contraseña"
                    required
                  />
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleTogglePasswordLogin}
                    />
                    Mostrar Contraseña
                  </label>
                  <button className='login-button' type="submit">Iniciar sesión</button>
                  <button className='google-button' onClick={signInWithGoogle}>Iniciar sesión con Google</button>
                  <p className="forgot-password" onClick={handleResetPassword}>
                    ¿Olvidaste tu contraseña?
                  </p>
                  </div>
                </form>
                {loginError && <p className="error-message">{loginError}</p>}
               
            </div>

            <div className='signup-column'>
              <h2>Crear cuenta</h2>
              <form className='form-container' onSubmit={(e) => {
                  e.preventDefault();
		  const name = e.target.name.value;
                  const email = e.target.email.value;
                  const password = e.target.password.value;
                  const confirmPassword = e.target.confirmPassword.value;
                  handleSignUp(email, password, confirmPassword, name);
                }}>
                  <div className="form-column-login">
	          <input type="name" name="name" placeholder="Nombre" maxLength="12" required />
                  <input type="email" name="email" placeholder="Correo" required />
                  <input
                    type={showPasswordSignup ? 'text' : 'password'}
                    name="password"
                    placeholder="Contraseña"
                    required
                  />
                  <input
                    type={showPasswordSignup ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder="Confirmar contraseña"
                    required
                  />
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleTogglePasswordSignup}
                    />
                    Mostrar Contraseña
                  </label>
                  <button className='login-button' type="submit">Crear cuenta</button>                  
                  </div>
                </form>
                {signupError && <p className="error-message">{signupError}</p>}

            </div>

          </div>

      </div>
  );
};

export default Profile;

import React, {useState} from "react";
import './Balance.css';

const Balance = ({balance, user}) => {
    
	const handleClick = () => {
        const email = 'ivyrevest@gmail.com';
        const subject = encodeURIComponent('Agregar balance a Ivy Revest');
        const body = encodeURIComponent(`Mis datos son:\n\nNombre de usuario: ${user.displayName}\nCorreo electrónico registrado: ${user.email}\nID de usuario: ${user.uid}\n\nAdjunto confirmación de pago.`);
        const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
        window.open(url, '_blank');
    };

    return (
        <div className='balance'>
                <div className="balance-title">
                    <p><strong>Balance:</strong> {balance.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
                </div>
	    	<div className="no-suscription">
                    <button className="suscribirse-button" onClick={handleClick}>Agregar balance</button>
                </div> 
        </div>
    );
};

export default Balance;

// Checkout.js
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Checkout.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const Checkout = ({ cartItems, increaseQuantity, decreaseQuantity, removeFromCart, balance }) => {

  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [errorBalance, setErrorBalance] = useState('');

  const continueShopping = () => {
    navigate('/');
  };

  const handleCheckout = () => {
    console.log('handleCheckout', balance);
    // user can only place order if it has a balanced in the account
    if (balance  == 0) {
      // not subscribed yet
      setErrorBalance('Suscripción no activa, por favor suscríbete para poder realizar compras');
    } else if (balance < getTotalPrice()) {
      // not enough balance
      // TODO: should i include the shipping cost?
      setErrorBalance('Saldo insuficiente, por favor recarga tu cuenta para poder realizar compras');
    } else {
      // good to place order
      if (user) {
        navigate('/checkout/checkoutStep2');
      } else {
        navigate('/checkout/checkoutStep1');
      }
    }
  };

  const getTotalPrice = () => {
    return parseFloat(cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2));
  };

  const getTotalPriecBs = () => {
    return parseFloat(cartItems.reduce((total, item) => total + item.priceBs * item.quantity, 0).toFixed(2));
  };

  const handleClick = (item) => {
    navigate(`/${item.tabName}/${item.brand}/${item.catalogue}/${item.name}`);
  };

  return (
    <div className="checkout-container">
      
      {cartItems.length === 0 ? (
        <div className="content-emptycar">
          <p>Tu carrito está vacío!</p>
          <button onClick={continueShopping}>CONTINUAR COMPRANDO</button>
        </div>
      ) : (
        
        <div className="content-car">
            <table className="cart-items-table">
              <thead>
                <tr>
                  <th>PRODUCTO</th>
                  <th>MEDIDA</th>
                  <th>PRECIO</th>
                  <th>CANTIDAD</th>
                  <th>TOTAL</th>
                  <th>ELIMINAR</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item, index) => (
                  <tr key={index}>
                    {item.tabName === 'molduras' ? (
                        <td onClick={() => handleClick(item)}>{item.brand + ' ' + item.catalogue + ' ' + item.product_description + ' ' + item.producttype + ' ' + item.name}</td>
                      ) : (
                        <td onClick={() => handleClick(item)}>{item.brand + ' ' + item.catalogue + ' ' + item.name}</td>
                      )}
                    <td>{item.medida}</td>
                    <td>
                      <p> {item.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </p>
                      <p> Bs. {item.priceBs.toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </p>       
                    </td>
                    <td className='quantity-table'  style={{ textAlign: 'center' }}>
                      <button className='button-checkoutpage' onClick={() => decreaseQuantity(item)}>-</button>
                      <span>{item.quantity}</span>
                      <button disabled={item.quantity === parseInt(item.quantityAvailable)} className='button-checkoutpage' onClick={() => increaseQuantity(item)}>+</button>
                    </td>
                    <td>
                      <p>{(item.price * item.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </p>
                      <p>Bs. {(item.priceBs * item.quantity).toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>       
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <button className='button-checkoutpage' onClick={() => removeFromCart(item)}>X</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          <div className="total-price-column">
            <h2>TOTAL DEL CARRITO</h2>
            <div className='column-subtotal'>
              <p>Subtotal:</p>
              <div className='prices-column'>
                <p>{getTotalPrice().toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
                <p className="price">Bs. {getTotalPriecBs().toLocaleString('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
            </div>
            <button className="checkout-button" onClick={handleCheckout}>FINALIZAR COMPRA</button>
	     {errorBalance && (
              <p className="error-message" style={{ textAlign: 'center', margin: '0 auto' }}>{errorBalance}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;



import React from 'react';
import './CartIcon.css';
import { MdOutlineShoppingBag } from "react-icons/md";

const CartIcon = ({ totalItems }) => {
  
  return (
    <div className="cart-icon-display">
      <MdOutlineShoppingBag className='custom-icon-class'/>
      {totalItems > 99 && <span className="cart-item-count-3">{totalItems}</span>}
      {(totalItems > 0 && totalItems <= 99)  && <span className="cart-item-count">{totalItems}</span>}
    </div>
  );
};


export default CartIcon;

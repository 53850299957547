import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return (

      <div className='main-page-home'>

        <div className='banner'>
          <img className='banner-image desktop' src={'/images/background.jpg'} alt="Novamur"/>
          <img className='banner-image mobile' src={'/images/backgroundPhone.jpg'} alt="Novamur"/>
          <h1 className="banner-text">REVESTIMIENTOS</h1>
        </div>

        <div className='row-tabs'>
          <div className='column-tab>'>
            <img className='circular-img' src={'/images/modulares.jpeg'} alt="Modulares" onClick={() => {
              navigate('/alfombrasmodulares');
              window.scrollTo(0, 0); 
            }} />
            <p className='tab-img-name' onClick={() => {
              navigate('/alfombrasmodulares');
              window.scrollTo(0, 0); 
            }}>
              Alfombras Modulares &rarr;
            </p>
          </div>

          <div className='column-tab>'>
            <img className='circular-img' src={'/images/papeltapiz.jpeg'} alt="Papel Tapiz" onClick={() => {
              navigate('/papeltapiz');
              window.scrollTo(0, 0); 
            }} />
            <p className='tab-img-name' onClick={() => {
              navigate('/papeltapiz');
              window.scrollTo(0, 0); 
            }}>
              Papel Tapiz &rarr;
              </p>
          </div>

          <div className='column-tab>'>
            <img className='circular-img' src={'/images/alfombras.jpeg'} alt="Alfombras" onClick={() => {
              navigate('/alfombras');
              window.scrollTo(0, 0); 
            }}/>
            <p className='tab-img-name' onClick={() => {
              navigate('/alfombras');
              window.scrollTo(0, 0); 
            }}>
              Alfombras &rarr;
            </p>
          </div>

	<div className='column-tab>'>
            <img className='circular-img' src={'/images/molduras.jpeg'} alt="Molduras" onClick={() => {
              navigate('/molduras');
              window.scrollTo(0, 0); 
            }}/>
            <p className='tab-img-name' onClick={() => {
              navigate('/molduras');
              window.scrollTo(0, 0); 
            }}>
              Molduras &rarr;
            </p>
          </div>
        </div>

        <div className='brands'>
           <img src={'/images/novamur.jpg'} alt="Novamur" onClick={() => {window.open('https://novamur.com/en/home-en/', '_blank');}}/>
           <img src={'/images/ragolle.jpg'} alt="Ragolle" onClick={() => {window.open('https://www.ragolle.com/', '_blank');}}/>
           <img src={'/images/itc.jpg'} alt="Ragolle" onClick={() => {window.open('https://www.itc-carpets.com/', '_blank');}}/>
		<img src={'/images/noelandmarquet.jpg'} alt="Ragolle" onClick={() => {window.open('https://noel-marquet.net/', '_blank');}}/>
        </div>

      </div>

  );
};

export default Home;


import React from 'react';
import './ProfileIcon.css';
import { BsPerson } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";

const ProfileIcon = ({user}) => {

  return (
    <div className='profile-icon-display'>
      {user ? (
          <BsPersonFill className='profile-icon-class'/>
        ) : (
          <BsPerson className='profile-icon-class'/>
        )}

    </div>
  );
};

export default ProfileIcon;
